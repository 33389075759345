import React from "react";
import { Box, Divider, styled, useTheme, useMediaQuery } from "@mui/material";
import { ImageComp } from "./common/ImageComp";
import { SubTitleComp } from "./common/SubTitleComp";
import { QuestionComp } from "./common/QuestionComp";
import { HaveAccount } from "./common/HaveAccount";
import { useSelector } from "react-redux";
import { Location } from "./stepsComps/Location";
import { Age } from "./stepsComps/Age";
import { Username } from "./stepsComps/Username";
import { Password } from "./stepsComps/Password";
import { Email } from "./stepsComps/Email";
import { ProgressBarComp } from "./common/ProgressBarComp";

const SignupDialogBox = styled(Box)(({ theme }) => ({
  minHeight: "auto",
  width: "100%",
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

const ImageCompWrapperBox = styled(Box)({
  background: "#3265AA",
  width: "48%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const MainContentBox = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "32px 16px 16px",
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.up("md")]: {
    width: "52%",
    padding: "48px 20px",
  },

  [theme.breakpoints.up("lg")]: {
    padding: "48px",
  },
}));

export const SignupDialog = () => {
  const activeStep = useSelector((state) => state.activeStep.value);
  const theme = useTheme();
  const isSmallScreenMd = useMediaQuery(theme.breakpoints.down("md"));
  const userData = useSelector((state) => state.userData?.value);

  const webImgSrc =
    activeStep === 2
      ? `web_${activeStep}_${userData.gender}`
      : `web_${activeStep}`;

  const formStepsCompArr = [
    <Location />,
    <Age />,
    <Username />,
    <Password />,
    <Email />,
  ];

  return (
    <SignupDialogBox>
      {!isSmallScreenMd && (
        <ImageCompWrapperBox>
          <ImageComp imgSrc={webImgSrc} sx={{ maxWidth: "80%" }} />
        </ImageCompWrapperBox>
      )}

      {isSmallScreenMd && (
        <HaveAccount
          sx={{
            padding: "24px 16px 16px",
            boxShadow: "0px 4px 12px 0px #00000014",
          }}
        />
      )}

      <MainContentBox>
        {!isSmallScreenMd && (
          <ImageComp
            imgSrc="logo"
            sx={{
              mb: { xs: "48px", lg: "60px" },
              width: { xs: "150px", md: "202px", lg: "254px" },
              height: "auto",
            }}
          />
        )}

        {isSmallScreenMd && <ProgressBarComp />}

        {activeStep === 1 && (
          <SubTitleComp
            text="signup_title"
            sx={{ mb: { xs: "36px", lg: 5 } }}
          />
        )}

        <QuestionComp />

        <Box
          sx={{
            flex: "1 1 auto",
            minHeight: { xs: "170px", lg: "202px" },
          }}
        >
          {formStepsCompArr[activeStep - 1]}
        </Box>

        {!isSmallScreenMd && (
          <Box sx={{ flex: "0 0 auto" }}>
            <Divider sx={{ mt: { xs: "36px", md: "20px", lg: 6 }, mb: 3 }} />
            <HaveAccount />
          </Box>
        )}
      </MainContentBox>
    </SignupDialogBox>
  );
};
