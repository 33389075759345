import React from "react";
import { Box, styled, useTheme, useMediaQuery } from "@mui/material";
import { ImageComp } from "./ImageComp";
import { SubTitleSmallComp } from "./SubTitleSmallComp";
import { TextComp } from "./TextComp";

const InfoCartBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const ImageWrapperBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const InfoCart = ({ item = {}, sx = {} }) => {
  const {
    imgSrc = "",
    subTitle = "",
    textArr = null,
    textArrWeb = null,
    textArrMobile = null,
  } = item;
  const theme = useTheme();
  const isSmallScreenMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreenLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <InfoCartBox sx={sx?.boxStyles}>
      {imgSrc && (
        <ImageWrapperBox sx={{ ...sx?.imgWrappStyles }}>
          <ImageComp
            imgSrc={imgSrc}
            sx={{ height: "fit-content", maxWidth: "100%", ...sx?.imgStyles }}
          />
        </ImageWrapperBox>
      )}
      <Box>
        <SubTitleSmallComp
          text={subTitle}
          sx={{ marginBottom: { xs: "12px", md: "16px" }, ...sx?.titleStyles }}
        />
        {(textArr || (isSmallScreenMd ? textArrMobile : textArrWeb))?.map(
          (item, idx) => (
            <TextComp
              text={item}
              key={idx}
              variant={isSmallScreenLg ? "Body4" : "Body1"}
              sx={{ ...sx?.textStyles }}
            />
          )
        )}
      </Box>
    </InfoCartBox>
  );
};
