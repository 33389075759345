export const dialogContent = {
  //General Terms Of Service content
  general_terms_of_service: `General Terms of Service`,

  general_terms_of_service_content_1: `Any and all visits and usage of this website, and the services it provides, will be governed by the following general Terms of Service. Please read this document thoroughly before using the website or any of its services.`,

  general_terms_of_service_content_2: `If you find any parts of our Terms of Service to be objectionable, or you feel that you cannot accept them, or if you feel that you have trouble reading and understanding them, then please discontinue use of this website immediately. Use of this website and our included services implies that you fully and without reservation accept these General Terms of Service.`,

  general_terms_of_service_content_3: `General Terms of Service`,

  general_terms_of_service_content_4: `Definitions`,

  general_terms_of_service_content_5: `Scope and Characteristics of the Conditions`,

  general_terms_of_service_content_6: `Content and Use of intimflorts.com`,

  general_terms_of_service_content_7: `Liability`,

  general_terms_of_service_content_8: `Content`,

  general_terms_of_service_content_9: `Privacy, Personal Information, Communications, and Website Usage`,

  general_terms_of_service_content_10: `Credit Rates and Payments`,

  general_terms_of_service_content_11: `Timeliness of Refunds (Time of Reflection)`,

  general_terms_of_service_content_12: `Complaints and Resolutions`,

  general_terms_of_service_content_13: `User Disputes`,

  general_terms_of_service_content_14: `Intellectual Property Rights`,

  general_terms_of_service_content_15: `Changes in Prices and General Conditions`,

  general_terms_of_service_content_16: `1. Definitions`,

  general_terms_of_service_content_17: `This refers to the limited liability Company, registered in the business register with organization number`,

  general_terms_of_service_content_18: `with headquarters in`,

  general_terms_of_service_content_19: `It is responsible for both personal data and as operator and owner of intimflorts.com, and is henceforth to be referred to as "Company", "Operator", "Us" or "We".`,

  general_terms_of_service_content_20: `Account: the account that a User has created for their own personal use and not for use to represent a group, business, or any other enterprise, nor for the use of representing other persons or third parties, including, but not limited to, impersonations and satirizations.`,

  general_terms_of_service_content_21: `Content: the published information displayed on this website, including, but not limited to, images and text, as well as any and all user-generated content.`,

  general_terms_of_service_content_22: `Credits: the virtual unit of value purchased by the User in accordance with the provisions of Article 7. The User may use Credits to send messages, images and to subscribe to premium features or any other services offered by the Company.`,

  general_terms_of_service_content_23: `Logs: digital records of any and all activity that occurs on the intimflorts.com website.`,

  general_terms_of_service_content_24: `Intellectual property rights: all intellectual property rights, including, but not limited to, copyright, trademarks, database rights, and other relevant rights, as well as related rights such as expertise and other knowledge derived from these rights.`,

  general_terms_of_service_content_25: `Messages: digital, protected communication that the User can exchange with other Users of the website and which is visible to the User when logged into their personal account.`,

  general_terms_of_service_content_26: `Profile: the personal information, preferences, and other content as set by a User that are searchable and visible to other Users.`,

  general_terms_of_service_content_27: `Services: the service provided by the Company and this website, primarily the platform for sending and receiving messages and other content to and from other Users.`,

  general_terms_of_service_content_28: `User: the individual person who visits this website and/or uses the primary Services or any other products offered by the Company, hereafter referred to as “you” or “your”.`,

  general_terms_of_service_content_29: `Website: the hosted, visible vehicle for presenting information and services via the Internet at the domain name intimflorts.com.`,

  general_terms_of_service_content_30: `2. Scope and Characteristics of the Conditions`,

  general_terms_of_service_content_31: `These Terms of Service (ToS) govern the relationship between Company and the User.`,

  general_terms_of_service_content_32: `These Terms of Service apply to the use of intimflorts.com and all of its subdomains, if any.`,

  general_terms_of_service_content_33: `Scope: Unless in direct violation of local laws and ordinances, these general Terms of Service shall take precedence, and shall remain in effect until such a time as they are rendered deprecated by forthcoming local laws.`,

  general_terms_of_service_content_34: `If or when any of these provisions in these Terms are eliminated or reversed, all other provisions shall continue to be effective. Likewise, any new provisions added should be considered valid as well from the moment that they are added to these Terms.`,

  general_terms_of_service_content_35: `3. Content and Use of intimflorts.com`,

  general_terms_of_service_content_36: `The purpose of intimflorts.com is to enable Users to exchange flirty, entertaining and exciting conversations. intimflorts.com provides the platform on which Users can chat and communicate with fictitious profiles. The website, service and content are being provided for entertainment purposes only and are not intended to be used for establishing dates or meetings in person.`,

  general_terms_of_service_content_37: `intimflorts.com and the Services it offers may be used only by persons who are at least 18 years of age. If a User is under the age of 18, that User is to discontinue use of the website. If a User has objections to the content of the website, and/or the User of this website is located in an area where access to the website or the use of the Services offered is prohibited by law, that User must terminate use of intimflorts.com immediately.`,

  general_terms_of_service_content_38: `The User may create a profile by completing the registration process. The User must provide valid and complete information about themselves as indicated in the profile information entry form. The User warrants that any relevant changes to the User’s life will be updated immediately to their intimflorts.com profile. Our support team shall review any and all submitted photos and textual content in order to ensure they comply with the terms set forth in this agreement. If a User’s displayed information is no longer accurate then the User must update it. If a User’s profile is found to furnish incorrect or incomplete information, the Company may terminate or suspend the User's account. By creating your profile, you grant intimflorts.com use of any and all intellectual property rights to the content you have provided. Specifically, the Company reserves the right to display, alter, translate, scan, and copy; use for public purposes, whether commercial or non-commercial; and license or transfer content related to profiles.`,

  general_terms_of_service_content_39: `Users are not allowed to create accounts for other people or entities, nor are they allowed to permit other people or entities to use their account, which includes, but is not limited to, selling their account.`,

  general_terms_of_service_content_40: `The User is solely responsible for knowledge of their own personal password. As such, the User is responsible for anything third parties may do to their account or other content on the site no matter how the third party obtained access to the account. If a User feels that their account may be being accessed by a third party — or “hacked” — or otherwise compromised, the User must immediately notify the Company immediately.`,

  general_terms_of_service_content_41: `Company shall not be held responsible for the use or improper use of a User's account.`,

  general_terms_of_service_content_42: `Obtaining access to intimflorts.com without authorization is a crime. If unauthorized access is suspected, the Company will report it to the relevant authorities immediately.`,

  general_terms_of_service_content_43: `The User may not copy or otherwise repurpose any content from other profiles other than for personal and private purposes.`,

  general_terms_of_service_content_44: `Users are not allowed to disseminate any information or other content that violates the rights of others. Users are also not allowed to publish content that is hateful, abusive, obscene, offensive, violent, or defamatory. Users also may not post content that would provoke political racism or violence. Users are not allowed to post any content that is in violation of their own local laws and provisions or that would place a User in violation of any other legal mandates, including, but not limited to, no-contact orders.`,

  general_terms_of_service_content_45: `Users may not utilize intimflorts.com in any way as part of an illegal act, criminal offense, and/or any actions that are in violation of the law. This includes, but is not limited to, theft or illegal dissemination of secret or confidential information; illegal or criminal distribution of text or visual or audio material involving racist statements, pornography of minors, criminal computer traffic, abusive expressions and so-called "e-mail bombs"; hacking of computers via the intimflorts.com website; destruction, damage or interference of use of systems or automated work or software by others; disseminating viruses or in any other way intentionally causing problems in communication or data storage; or provide access through fake keys, codes and credentials; or infringement of intellectual property rights by third parties (including copyright, trademark rights, rights under European Directive 96/9 on statutory protection of databases, patent rights, portrait rights or model rights).`,

  general_terms_of_service_content_46: `Users may not use the personal information of third parties on intimflorts.com for any purpose, which includes posting said information, or sending messages to persons using information from intimflorts.com. The User may not send spam, chain letters, or otherwise take part in the bulk distribution of emails to Users or former Users.`,

  general_terms_of_service_content_47: `Users may post photographs of themselves on their profile, however Users may not post photographs of others, including intimflorts.com Users and non-Users. intimflorts.com reserves the right to refuse, modify, or delete photos and other images.`,

  general_terms_of_service_content_48: `Users are not permitted to request personal contact information, such as phone numbers, addresses, true full names, email addresses, URLs and links, profiles on other websites, or other personally identifiable information from other Users. In addition, Users may not post to the website, nor their own profiles, or share via messages this type of personally identifiable information. intimflorts.com reserves the right to reject, delete or edit profiles or it finds to be in violation of any of these Terms.`,

  general_terms_of_service_content_49: `Persons with a criminal offense of a sexual nature are not allowed to use intimflorts.com and should discontinue any use of it immediately.`,

  general_terms_of_service_content_50: `If a User has been suspended or banned from intimflorts.com they are not allowed to make a new or secondary profile. Users who are found to be creating new profiles will be banned.`,

  general_terms_of_service_content_51: `4. Liability`,

  general_terms_of_service_content_52: `Company Uses any and all effort to keep intimflorts.com available, accessible uninterrupted and secured by all reasonable means.`,

  general_terms_of_service_content_53: `Users shall not hold Company responsible in any way for conditions interfering with any work, problems, interference, alteration or limitation of functionality or other matter affecting the availability of intimflorts.com or any other Services provided by Company.`,

  general_terms_of_service_content_54: `If you believe that your artwork, prose, or other content has been used on intimflorts.com in a manner that infringes on your copyright, or that your intellectual property rights have otherwise been infringed, we ask that you contact us directly.`,

  general_terms_of_service_content_55: `Company holds no responsibility for the correctness, completeness and duration of profiles, content reflecting the opinions of the Users profiles in social media, advertisements or contributions that may have entertainment as their purpose. We are not liable for any direct or indirect damage resulting from the use of intimflorts.com, in which case we exclude all liability to the extent permitted by law, regardless of the nature of the action, for acts or disregard of Users.`,

  general_terms_of_service_content_56: `5. Content`,

  general_terms_of_service_content_57: `External content linked from intimflorts.com is not under the control of the Company; the content of and all external sites are the responsibility of whomever administers them. The content of intimflorts.com is curated carefully with utmost caution. We are not responsible for the correctness, completeness and the pertinence of the information made available. The contributions reflect the views of the author. Profiles, characters, advertisements, verbal descriptions and visual material can have sexual and erotic nature meant for entertainment purposes.`,

  general_terms_of_service_content_58: `intimflorts.com is built with the notion of entertainment, it is explicitly not the purpose of intimflorts.com to establish physical arrangements or meetings.`,

  general_terms_of_service_content_59: `The company expressly reserves the right to use profiles within the system to communicate with Users for entertainment purposes and to enable us to view and learn more about User preferences, usage trends, and to gain information about how our members interact.`,

  general_terms_of_service_content_60: `The company shall not be held responsible for profiles that violate the law. If any User suspects that any profile may be displaying content that is illegal, the intimflorts.com administrators must be notified immediately. The Company will do everything possible to block, remove, or otherwise take down these profiles if they are found in violation of the law, and shall likewise do everything possible to prevent them from returning.`,

  general_terms_of_service_content_61: `Company uses third-party websites to send visitors to its website, sometimes with and sometimes without a referral payment. These third parties are responsible for the content of their own websites, the placement of hyperlinks as well as the way they link the User to intimflorts.com.`,

  general_terms_of_service_content_62: `The User shall grant Company usage rights to any and all content uploaded by User. This content includes images, videos, descriptions, text, and all other User-generated matter. These usage rights include, but are not limited to, the right to display, modify, translate, duplicate, and use for advertising, whether for commercial or non-commercial purposes. In addition, the User grants Company the right to license, lend, or lease any and all User content as it sees fit with no expected remuneration to User.`,

  general_terms_of_service_content_63: `The User shall only upload images or other assets that they have a fair use claim to. Company does not infer any ownership of User content except where indicated by disclaimer. If Company determines that the User has uploaded content to which they do not have a valid and reasonable fair use claim then Company reserves the right to delete such content without warning.`,

  general_terms_of_service_content_64: `Our support staff has the right to monitor and moderate the chat system to ensure acceptable compliance with these Terms of Use.`,

  general_terms_of_service_content_65: `6. Privacy, Personal Information, Communications, and Website Usage`,

  general_terms_of_service_content_66: `Company respects the privacy of Users of intimflorts.com. For more information, see the privacy statement at intimflorts.com.`,

  general_terms_of_service_content_67: `During any visit to intimflorts.com, User's information is logged. This information is inherent for intimflorts.com to function, as well as to make intimflorts.com and/or other Services offered by the Company better. Occasionally, such information may also be used to locate and repair technical errors. It may also be used to identify violations of these Terms of Service, statutory provisions, or other matters that pertain to the legal use and quality functioning of intimflorts.com.`,

  general_terms_of_service_content_68: `Company respects User's information and will never sell it, share it, or in any other way disseminate it to third parties.`,

  general_terms_of_service_content_69: `In the event that these ToS are violated, or of actions that are in conflict with the law, and/or in the cases of fraud, a User's account will be blocked immediately, without prior notice, and membership may be terminated. When applicable, any purchased balance remaining in the User’s account may be immediately deleted. Any costs associated with tracking their abuse or fraud should be reimbursed to the Company by the User. Company reserves the right to claim damages as well as any and all legal and non-legal costs incurred by the User.`,

  general_terms_of_service_content_70: `Users shall consent to receiving text messages for purposes of account verification, message notifications, and other purposes related to the Service when they provide the Company with their mobile number. The Company will not charge Users for such messages, however a User’s carrier may assign charges for text messages and/or data transmitted. The Company is in no way responsible for those charges. The Company may send text messages through cellular operators or other networks. As such, the Company is not responsible for the timelines or final delivery of text messages, as this is the responsibility of the cellular telephone operators and their networks.`,

  general_terms_of_service_content_71: `Text messages and cellular numbers shall be protected and not shared by the Company with any third parties as per 6.3.`,

  general_terms_of_service_content_72: `7. Credit Rates and Payments`,

  general_terms_of_service_content_73: `Users can pay for any for-cost Services by purchasing credits by credit or debit card payment. The rates displayed on intimflorts.com will always reflect the price after any and all applicable taxes have been added.`,

  general_terms_of_service_content_74: `The sending of a single message, photo or gift cost 60 credits. A single message is limited to 300 characters. A User may sometimes receive free or promotional credits, and these may be invalidated or rescinded at any time.`,

  general_terms_of_service_content_75: `In the event of a dispute regarding payment and credits, the Company shall refer to any and all available information within intimflorts.com's system. This data will be the determining factor for any and all amounts owed to a User, unless such User can provide convincing evidence to the contrary.`,

  general_terms_of_service_content_76: `Credits purchased by a user come with an expiration date. If a User has been inactive for more than 90 days on intimflorts.com the User's account may be terminated, and any and all content accrued by the user, including any outstanding credit balance will be deleted. A terminated User will not be able to claim any refunds from their outstanding credit balance.`,

  general_terms_of_service_content_77: `If a User deletes their own account, any and all credits held in their balance will be deleted and cannot be recovered.`,

  general_terms_of_service_content_78: `Credit granted to the user due to a (technical) error will in principle not be refunded. Should a (technical) error occur at the Company, the user may submit a written request for a refund of credits that have been issued caused by this error. In such cases, refunds will not be withheld on unreasonable grounds.`,

  general_terms_of_service_content_79: `When a User purchases credit, that credit is delivered from the Company immediately upon payment by the User. Users waive their right to cancel the credit purchase agreement as soon the purchase of credits is finished, meaning that credits cannot be exchanged for money. Please note that we do not operate with standing or recurring payments. Our members decide for themselves when and how often they want to buy a new credit package.`,

  general_terms_of_service_content_80: `If a User asks for a refund via their credit card clearinghouse without a valid reason, then all costs associated with this refund will be charged to the User.`,

  general_terms_of_service_content_81: `8. Timeliness of Refunds (Time of Reflection)`,

  general_terms_of_service_content_82: `The company offers digital services, where you have the right to terminate the agreement within 14 days. If the user uses the paid services within these 14 days, the right of refund is waived. Refunds shall be made within 14 days via the payment method used by the User. If the original payment method is not viable, such as payment with a prepaid credit or gift card, the User may provide a bank account number for direct transfer of funds. The right of withdrawal expires after 14 days.  \n \n To exercise a User’s right of withdrawal, you must send the Company a written statement by email to inform the administrators of intimflorts.com of your decision to cancel the contract. To comply with the right of withdrawal period, it is sufficient that a User declares your use of the right of withdrawal before the 14 day deadline has expired.`,

  general_terms_of_service_content_83: `9. Complaints and Resolutions`,

  general_terms_of_service_content_84: `Company shall handle all complaints relating to the use of the intimflorts.com in a thorough and timely manner.`,

  general_terms_of_service_content_85: `A User must submit a written complaint by sending an email to Company via the contact page on intimflorts.com.`,

  general_terms_of_service_content_86: `Once a formal complaint is received, the Company will do everything possible to resolve the complaint within 10 business days. Company shall notify the User of the resolution or any request for more information in writing via email.`,

  general_terms_of_service_content_87: `10. User Disputes`,

  general_terms_of_service_content_88: `If you have a discrepancy with another user, you shall relieve intimflorts.com from all types of claims or damages (direct or indirect), known and unknown, suspected and unknown, revealed or undiscovered, as a result of or associated with such cases.`,

  general_terms_of_service_content_89: `11. Intellectual Property Rights`,

  general_terms_of_service_content_90: `Any and all intellectual property (IP) and similar rights for all products and information, including aforementioned Services, offered on intimflorts.com are solely the property of the Company or its licensors.`,

  general_terms_of_service_content_91: `As such, no part of intimflorts.com may be reproduced, copied, scanned, or rehosted without the specific written permission of the Company. The intangible rights to all texts, images, sounds and software, including but not limited to source code, on intimflorts.com belong to the Company or its licensors.`,

  general_terms_of_service_content_92: `A User’s texts, images, sounds and/or software sent to Company or uploaded to intimflorts.com, will constitute a transfer of all intellectual property rights to Company, (as per 5.6, above), and therefore shall hold the Company harmless from any third party claims regarding such content. Company is likewise permitted to utilize these contributions commercially.`,

  general_terms_of_service_content_93: `In the event that the Company is made aware of a claim of infringing material or infringing links on the website, Company has the absolute right, though not the obligation, to hide or delete any such content from intimflorts.com. Likewise, any accounts uploading, posting, or copying such content may be terminated immediately (as per 6.4, above).`,

  general_terms_of_service_content_94: `12. Changes in Prices and General Conditions`,

  general_terms_of_service_content_95: `Company reserves the right to change the Services and prices published on the website at will, without prior notification. Such changes to the website may include adjusting the Terms of Service, updating them, or executing additions. It is User's responsibility to completely read through these Terms of Service regularly, any continued use of intimflorts.com after the effective date shall imply that a User has in fact read through and agrees with any adjustments made.`,

  general_terms_of_service_content_96: `Uses shall not hold the Company responsible for any perceived damages that may occur due to changes to the content of intimflorts.com or these Terms of Service.`,

  general_terms_of_service_content_97: `13. Governing Law`,

  general_terms_of_service_content_98: `All relations and any possible disputes arising from these Terms shall be settled exclusively under Cyprus law. This shall not affect the rights of consumers arising from the generally binding provisions of law.`,

  //Privacy Statement
  privacy_statement: `Privacy Statement`,

  privacy_statement_content_1: `These privacy policies and our Terms and Conditions describe how we handle personal data.`,

  privacy_statement_content_2: `1. Your data and definitions`,

  privacy_statement_content_3: `By submitting my account data, I understand that the website operator will process my personal data in accordance with the`,

  privacy_statement_content_3b: `privacy statement.`,

  privacy_statement_content_4b: `Account:`,

  privacy_statement_content_4: `A registration on the site that allows the visitor to establish contact with other profiles;`,

  privacy_statement_content_5b: `GDPR:`,

  privacy_statement_content_5: `EU Data Protection Regulation, including the laws implemented in these regulations;`,

  privacy_statement_content_6b: `Member database:`,

  privacy_statement_content_6: `The database wherein all member data is stored. A member has a personal account and can access it with a unique combination of username and password;`,

  privacy_statement_content_7_1: `The private company with limited liability, registered in the business register under organization number`,

  privacy_statement_content_7_2: `with headquarters in`,

  privacy_statement_content_7_3: `hereinafter considered responsible for both personal data and as operator, owner and manager of the website, and is henceforth to be referred to as "Company", "Operator", "Us" or "We".`,

  privacy_statement_content_8b: `Personal data:`,

  privacy_statement_content_8: `Information relating to an identified or identifiable natural person, as referred to in the GDPR;`,

  privacy_statement_content_9b: `Privacy Statement:`,

  privacy_statement_content_9: `The notice to the visitor regarding the handling of personal data concerning the person, including the basis for the handling of personal data concerning the person, the purpose of information processing, what happens to the information and who it is shared with;`,

  privacy_statement_content_10b: `Profile:`,

  privacy_statement_content_10: `A range of personal characteristics and preferences provided to ensure visibility to other visitors;`,

  privacy_statement_content_11b: `Registration:`,

  privacy_statement_content_11: `The action the visitor actively and consciously does by creating an account;`,

  privacy_statement_content_12b: `Visitors:`,

  privacy_statement_content_12: `A natural person who registers on the site;`,

  privacy_statement_content_13b: `Website:`,

  privacy_statement_content_13: `A medium for presenting information via the internet;`,

  privacy_statement_content_13_1: `The website stores and processes all data that you provide via the website. This data includes your account, your messages, photos and other generated content, your location data, your payment details and automatically generated information. Below, the basis for why and the way in which this is done is further elaborated.`,

  privacy_statement_content_14: `2. Your Account`,

  privacy_statement_content_15: `If you want to use the member database, you must register as a member. When you create an account, you will be asked to enter certain data about yourself. \n You will be asked to enter a valid email address, password, gender, place of residence and date of birth. In the account you can add data such as preferences, hobbies, personal characteristics and description. The website asks you for this data in order to tailor its services to you and put you in touch with profiles that may match your preferences. To the extent that you provide such personal data to the Website, you expressly authorize us to process such specific personal data.`,

  privacy_statement_content_16: `The data you share may be received by the following recipients:`,

  privacy_statement_content_17_1: `Data controller:`,

  privacy_statement_content_17_2: `the private company with limited liability, registered in the business register under organization number`,

  privacy_statement_content_17_3: `with head office at`,

  privacy_statement_content_17_4: `in addition to its Data Protection Officer.`,

  privacy_statement_content_18: `Developers and administrators of this platform.`,

  privacy_statement_content_19: `Entities where people are employed as controllers, where some of them come from countries that may not provide satisfactory protection of your personal data compared to data in your country. However, we have entered into binding agreements and apply the rules of good business conduct that we impose on these parties, so that we can comply with comparable levels of data protection.`,

  privacy_statement_content_20: `Intermediaries and other employees who control the site for inappropriate content.`,

  privacy_statement_content_21: `3. Your Email Address`,

  privacy_statement_content_22: `Your email address will be used to verify your account, as well as to contact you.`,

  privacy_statement_content_23: `You can create any username yourself, as long as it is not already in use. We may also use your email address to send you various messages, relevant suggestions and/or promotional emails about the Website. You will be given the opportunity to unsubscribe from receiving such information.`,

  privacy_statement_content_24: `4. Use by third parties`,

  privacy_statement_content_25: `If you provide personal data to this website, it will not be shared with third parties, except to the extent that is clear from this privacy statement.`,

  privacy_statement_content_26: `We may disclose your data to third parties to the extent that you have given explicit permission to do so, as well as when this is required to use the website. It also includes data that is (automatically) collected by the website, such as your IP or MAC address, browser, and the software and hardware you use. We refer here to the section “Automatically generated information”.`,

  privacy_statement_content_27: `This data is shared with Google Analytics and Everflow, two external service providers that ensure that our products work properly.`,

  privacy_statement_content_28: `Finally, we may disclose your personal data to third parties if necessary due to laws and regulations, if we are required to do so as a result of a lawsuit and/or if we deem this necessary to protect our rights.`,

  privacy_statement_content_29: `5. Your payment details`,

  privacy_statement_content_30: `To make full use of our services, you will need to make a purchase. For these purchases, we need your payment details, such as either a debit or credit card. This information will only be used to make purchases, through our trusted payment service provider. If you pay via bank transfer, you will be forwarded to the bank's website.`,

  privacy_statement_content_31: `6. Automatically generated information`,

  privacy_statement_content_32: `In order for the website to function optimally (for example, display pages correctly and secure the website), we collect a certain type of data. This data is generated automatically through your use of the website. This data consists of:`,

  privacy_statement_content_33: `the type of device you are using;`,

  privacy_statement_content_34: `the IP address of your device;`,

  privacy_statement_content_35: `the operating system you are using;`,

  privacy_statement_content_36: `the software of the browser you are using`,

  privacy_statement_content_37: `the pages you visit`,

  privacy_statement_content_38: `the ISP you use`,

  privacy_statement_content_39: `7. Your rights as a user of this website`,

  privacy_statement_content_40: `You have the right to withdraw your consent at any time if applicable. There are settings available in the website where you can limit the number of emails you receive, select what type of emails you receive or delete your account.`,

  privacy_statement_content_41: `Accessible in your account settings you have the option to delete your account, you will lose access to all of your contacts, chats and profile details should you wish to delete your account. You also have the right to inspect, change and request erasure of any personal data we collect or restrict a particular handling of this, as well as the right to oppose a specific processing of data. Finally, you have the right to transfer your personal data to another platform. Accessing, changing and transferring your data can in principle be done via your own profile, but if this is not possible you can contact customer service.`,

  privacy_statement_content_42: `You can ask questions or submit any concerns of the use of the website through our accessible contact form. An employee will then contact you with an answer to your question and/or process your message. For this purpose, we ask you to provide us with the following data:`,

  privacy_statement_content_43: `e-mail address;`,

  privacy_statement_content_44: `subject of your message;`,

  privacy_statement_content_45: `a description of your question/message;`,

  privacy_statement_content_46: `attachment (optional);`,

  privacy_statement_content_46_1: `right to erasure request form if relevant`,

  privacy_statement_content_47: `If you want to complain about the way we process your request, you may submit a complaint to the Office of the Commissioner for Personal Data Protection`,

  privacy_statement_content_48: `8. Basis for data processing`,

  privacy_statement_content_49: `By entering personal data on our website, you make it public and agree to our terms and conditions, and give explicit permission to us to process this data. In particular, your race, religion, sexual orientation, political affiliation, health, union membership, and criminal conduct are considered sensitive data, which you expressly consent to. \n This personal data is either provided voluntarily by you to optimize your experience of the website, or is necessary for us to be able to offer you our services in accordance with our terms and conditions. \n The categories of personal data, the purpose and the basis are described in more detail below.`,

  privacy_statement_content_50: `9. Categories of personal data`,

  privacy_statement_content_51: `Please note that your sexual preferences are linked to your personal data. By offering these, you agree that we will store and process your personal data.`,

  privacy_statement_content_52: `There are three categories of personal data, each with its own basis and reason for use, namely necessary personal data, optional personal data and sensitive personal data:`,

  privacy_statement_content_53: `Necessary personal data:`,

  privacy_statement_content_54: `This data is necessary for the company to be able to offer its services. The company can process this data on the basis of the general terms and conditions and the company’s legitimate interest.`,

  privacy_statement_content_55: `This applies to the following data:`,

  privacy_statement_content_56: `E-mail address`,

  privacy_statement_content_57: `IP address`,

  privacy_statement_content_58: `Username`,

  privacy_statement_content_59: `Password`,

  privacy_statement_content_60: `Place of residence`,

  privacy_statement_content_61: `Date of birth`,

  privacy_statement_content_62: `Payment details`,

  privacy_statement_content_63: `Necessary cookies`,

  privacy_statement_content_64: `We will use this data about you for the following purposes:`,

  privacy_statement_content_65: `Enable you to use the site, such as creating an account, communicating with other users and sending messages;`,

  privacy_statement_content_66: `Make payments`,

  privacy_statement_content_67: `Send you information about specific services for our website and answer your questions, feedback and alerts;`,

  privacy_statement_content_68: `Optional personal data`,

  privacy_statement_content_69: `This is data that is not strictly necessary for the company to be able to offer its services, but the opportunity to share such data is given with regard to the quality of the service. The company can process this data on the basis of the general terms and conditions and the company’s legitimate interest.`,

  privacy_statement_content_70: `This applies to the following data:`,

  privacy_statement_content_71: `Your profile information`,

  privacy_statement_content_72: `Your photos`,

  privacy_statement_content_73: `Your messages`,

  privacy_statement_content_74: `Your preferences`,

  privacy_statement_content_75: `Your personal characteristics`,

  privacy_statement_content_76: `Your hobbies`,

  privacy_statement_content_77: `Your description`,

  privacy_statement_content_78: `Cookies that are not strictly necessary, if any`,

  privacy_statement_content_79: `We will use this data about you for the following purposes:`,

  privacy_statement_content_80: `Optimize the delivery of services offered from this site. Based on the preferences you have specified, we will give you suggestions based on preset filters. This way you can find contacts faster and easier.`,

  privacy_statement_content_81: `Set up and customize user profiles;`,

  privacy_statement_content_82: `Tailor the site to your preferences and/or view personalized offers;`,

  privacy_statement_content_83: `Compile anonymous statistical data and use these for research purposes;`,

  privacy_statement_content_84: `Secure, customize and develop the site;`,

  privacy_statement_content_85: `Obtain data about you for third parties if you have given permission for this or based on legislation and/or regulations.`,

  privacy_statement_content_86: `Sensitive data`,

  privacy_statement_content_87: `This data is not strictly necessary for the company to be able to offer its services, and relates in particular to your race, religion, your sexual orientation, political views, health, trade union membership and criminal law. You give the company permission to process such data. \n The company can process this data on the basis of the general terms and conditions and the company’s legitimate interest.`,

  privacy_statement_content_88: `This applies to the following data:`,

  privacy_statement_content_89: `Your sexual orientation`,

  privacy_statement_content_90: `Your photos, if these contain sensitive data`,

  privacy_statement_content_91: `Your messages, if these contain sensitive data`,

  privacy_statement_content_92: `Your hobbies, if these contain sensitive data`,

  privacy_statement_content_93: `Your preferences, if these contain sensitive data`,

  privacy_statement_content_94: `Your personal characteristics, if these contain sensitive data`,

  privacy_statement_content_95: `Your description, if it contains sensitive data`,

  privacy_statement_content_96: `We will use this data about you for the following purposes:`,

  privacy_statement_content_97: `Optimize the delivery of services offered from this site. Based on the preferences you have specified, we will provide suggestions based on preset filters. This way you can find contacts faster and easier`,

  privacy_statement_content_98: `Set up and customize user profiles;`,

  privacy_statement_content_99: `Tailor the site to your preferences and/or view personalized offers`,

  privacy_statement_content_100: `In addition, we take the following measures to protect your sensible interests:`,

  privacy_statement_content_101: `We offer you the opportunity to withdraw if you no longer wish to share your data`,

  privacy_statement_content_102: `We protect your data using encryption`,

  privacy_statement_content_103: `We save your password as unreadable (hashed)`,

  privacy_statement_content_104: `10. Security`,

  privacy_statement_content_105: `We have taken appropriate measures to prevent unauthorized access by protecting and securing the data you have provided. A person who is responsible for data security has also been appointed and can be contacted via`,

  cookie_statement: `Cookie Statement`,

  cookie_statement_content_1: `The cookie statement for this website intimflorts.com explains how cookies are placed in the website intimflorts.com and with affiliated partners. Data is read from your computer, tablet and/or mobile phone (hereinafter: "Peripherals"). The cookie statement also provides information on the purpose for which this collected data is used. We therefore recommend that you read this cookie statement carefully.`,

  cookie_statement_content_2: `What are cookies?`,

  cookie_statement_content_3: `Cookies are small pieces of intimflorts.com data that are sent to your browser when you visit the website. This is then stored on the hard disk or in the memory of the peripherals. The browser may return this data during subsequent visits to the website. The cookies placed through this website cannot damage your peripherals or the files stored on these devices.`,

  cookie_statement_content_4: `Cookies from intimflorts.com`,

  cookie_statement_content_5: `intimflorts.com uses cookies that read data themselves. No third party is involved in the placement and reading of such so-called first-hand cookies. intimflorts.com primarily places functional cookies. These cookies are necessary to be able to provide the desired services. In the example intimflorts.com, the functional cookies ensure that, after accepting their use, you will no longer see the indication on the screen that the website uses cookies. These functional cookies are not subject to the laws concerning cookies, and intimflorts.com is therefore not required to inform you about this. Nevertheless, we inform you because we want to be open about the use of all types of cookies. In addition to functional cookies, intimflorts.com places cookies to remember your search results during a session in the browser. During a session, these cookies facilitate your use of the website. The cookies are deleted when you end your session in the browser.`,

  cookie_statement_content_6: `Third-party cookies`,

  cookie_statement_content_7: `Google Analytics`,

  cookie_statement_content_8: `To collect statistics about the use and visit of the website, intimflorts.com uses Google Analytics. Google Analytics places a permanent cookie in your peripherals, based on your recorded use of the site. This data is then analyzed by Google and the results are passed on to intimflorts.com. In this way, intimflorts.com gains insight into the way the website is used, and can make necessary adjustments to the website or services based on its use. \n Google may pass this data on to third parties if they are required by law to do so and/or if third parties process the data on behalf of Google. Google may not use the data for other Google services. We have completely anonymized the cookies in Google Analytics and can therefore place these without permission.`,

  cookie_statement_content_9: `Deletion of cookies`,

  cookie_statement_content_10: `The consent you have given to intimflorts.com regarding the placement and reading of cookies can be withdrawn at any time by setting your browser to not accept cookies and/or by removing all cookies that are already stored in your browser. You can read about how to delete different types of cookies in different internet browsers here. You must take into account that the deletion of cookies may result in some parts of the website not working or not working properly.`,

  cookie_statement_content_11: `Privacy statement`,

  cookie_statement_content_12: `It is possible that the data collected or otherwise read via a cookie contains personal data. If this is the case, the privacy statement of intimflorts.com applies to the processing of such personal data.`,

  cookie_statement_content_13: `Changes`,

  cookie_statement_content_14: `The cookie statement can be changed at any time. Changes to the cookie statement or this privacy statement will be made known via the website.`,

  cookie_statement_content_15: `Questions`,

  cookie_statement_content_16: `If you have questions about the cookie statement or this privacy statement, you can send an e-mail via the contact form.`,
};
