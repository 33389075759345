import React from "react";
import { GeneralTermsOfServiceDialog } from "./GeneralTermsOfServiceDialog";
import { PrivacyStatementDialog } from "./PrivacyStatementDialog";
import { CookiePolicyDialog } from "./CookiePolicyDialog";
import { SignupDialog } from "../components/SignupDialog";

export const DialogContentType = ({ type }) => {
  return (
    <>
      {type === "terms" && <GeneralTermsOfServiceDialog />}
      {type === "policy" && <PrivacyStatementDialog />}
      {type === "cookie" && <CookiePolicyDialog />}
      {type === "signup" && <SignupDialog />}
    </>
  );
};
