import React from "react";
import {
  Box,
  Typography,
  styled,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { InfoCart } from "./InfoCart";

const HowHelpsWinInfoCartsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",

  [theme.breakpoints.up("md")]: {
    width: "48%",
  },
}));

const CartsWrapperBox = styled(Box)(({ theme }) => ({
  display: "flex",
  marginBottom: "24px",

  [theme.breakpoints.up("md")]: {
    marginBottom: "60px",
  },
}));

const NumberBox = styled(Box)(({ theme }) => ({
  minWidth: "48px ",
  height: "48px",
  borderRadius: " 50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: theme.palette.orange,
  marginRight: "16px",

  [theme.breakpoints.up("md")]: {
    minWidth: "60px ",
    height: "60px",
    marginRight: "36px",
  },
}));

export const HowHelpsWinInfoCartsNumber = ({ arrData }) => {
  const theme = useTheme();
  const isSmallScreenMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <HowHelpsWinInfoCartsBox>
      {arrData?.map((item) => (
        <CartsWrapperBox key={item?.id}>
          <NumberBox>
            <Typography
              variant={isSmallScreenMd ? "Subtitle2" : "Subtitle1"}
              color={theme.palette.white}
            >
              {item.id}
            </Typography>
          </NumberBox>
          <InfoCart item={item} />
        </CartsWrapperBox>
      ))}
    </HowHelpsWinInfoCartsBox>
  );
};
