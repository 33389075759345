import React, { useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  Link,
  styled,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { ErrorMessage } from "../common/ErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../features/userDataSlice";
import { addErrorMessage } from "../../features/errorMessageSlice";
import { back, setActiveStep } from "../../features/activeStepSlice";
import { setModalState } from "../../features/modalStateSlice";
import { Translate } from "react-translated";
import { useDebounce } from "../../hooks/useDebounce";
import axios from "axios";
import { RecaptchaToken } from "./../common/RecaptchaToken";
import { InputComp } from "../common/InputComp";
import { ButtonsGroup } from "../common/ButtonsGroup";
import { TextTranslComp } from "../common/TextTranslComp";

const EmailCompBox = styled(Box)({
  width: "100%",
});

export const Email = () => {
  const userData = useSelector((state) => state.userData?.value);
  const [email, setEmail] = useState(userData.email || "");
  const [isChecked, setIsChecked] = useState(false);

  const apiUrl = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  const userId = sessionStorage.getItem("user_id");
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.errorMessage?.value);
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const message = "emailErrMessFE";
  const useDebounceValue = useDebounce(email, 1000, regex, message);
  const theme = useTheme();
  const isLoading = useSelector((state) => state.isLoading.value);
  const isSmallScreenMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (e) => {
    setEmail(e.target.value);
    dispatch(addErrorMessage(""));
  };

  useEffect(() => {
    if (useDebounceValue || !errorMessage) {
      dispatch(addField({ email: useDebounceValue }));
    } else {
      dispatch(addField({ email: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useDebounceValue]);

  const fetchCompleteData = async () => {
    try {
      const response = await apiUrl.post(
        `/registration/${userId}`,
        {
          email: useDebounceValue,
          DOB: `${userData.year}-${userData.month}-${userData.day}`,
          location: userData.location,
          gender: userData.gender,
          password: userData.password,
          looking_for: userData.looking_for,
          recaptcha_token: userData.recaptcha_token,
          ef_transaction_id: userData.ef_transaction_id,
        },
        {
          params: {
            site_key: "no01",
          },
        }
      );

      const getAccessToken = await apiUrl.post(
        `/sessions`,
        {
          password: userData.password,
          email: useDebounceValue,
          recaptcha_token: userData.recaptcha_token,
        },
        {
          params: {
            site_key: "no01",
          },
        }
      );
      localStorage.setItem(
        "access_token",
        getAccessToken?.data?.Data?.access_token
      );
      const accessToken = localStorage.getItem("access_token");

      if (response?.data.Status === "ok" && userId && accessToken) {
        window.location.href = `https://intimflorts.com/discovery/?token=${accessToken}`;
        dispatch(addErrorMessage(""));
      }
    } catch (error) {
      if (error?.response?.data.Status === "fail") {
        errorCodeChangeActiveState(error?.response?.data?.Error?.code);
        dispatch(addErrorMessage(error?.response?.data?.Error?.message));
      }
    }
  };

  const errorCodeChangeActiveState = (code) => {
    // age error
    if (code === 103) {
      dispatch(setActiveStep(2));
    }
    // password error
    else if (code === 102 || code === 105 || code === 118) {
      dispatch(setActiveStep(4));
    }
    // email error
    else if (code === 100 || code === 106) {
      dispatch(setActiveStep(5));
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked((prev) => !prev);
  };

  const handleNext = () => {
    if (useDebounceValue && isChecked) {
      try {
        dispatch(addErrorMessage(""));
        fetchCompleteData();
      } catch (error) {
        dispatch(addErrorMessage(error?.response?.data?.Error?.message));
      }
    }
  };

  const handleBack = () => {
    dispatch(back());
    dispatch(addErrorMessage(""));
  };

  return (
    <EmailCompBox className="userBox">
      {!isLoading ? (
        <>
          {!errorMessage ? (
            <TextTranslComp
              variant={isSmallScreenMd ? "Body6" : "Body3"}
              sx={{ mb: { xs: "12px", md: "16px" } }}
              text="emailText"
            />
          ) : (
            <ErrorMessage errorMessage={errorMessage} />
          )}

          <InputComp
            name="emailMain"
            value={email}
            handleChange={handleChange}
            placeholder="emailPlaceholder"
            typeInput="email"
            isValid={!useDebounceValue || !email || !userData.recaptcha_token}
            autoFocus={true}
          />

          <FormGroup sx={{ mt: { xs: 3, md: 4, lg: 5 } }}>
            <FormControlLabel
              sx={{
                margin: "0",
                display: "flex",
                alignItems: "flex-start",
              }}
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  sx={{
                    padding: "2px",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    "&.Mui-checked": {
                      color: theme.palette.lightGreen,
                    },
                  }}
                />
              }
              label={
                <Typography
                  variant={isSmallScreenMd ? "Body6" : "Body3"}
                  color={theme.palette.black}
                  sx={{
                    marginLeft: "10px",
                  }}
                >
                  <Translate text="read and accept" />{" "}
                  <Link
                    sx={{
                      color: theme.palette.lightGreen,
                      textDecoration: "none",
                    }}
                    onClick={() =>
                      dispatch(
                        setModalState({
                          isOpen: true,
                          type: "terms",
                          previousState: "signup",
                        })
                      )
                    }
                  >
                    <Translate text="Terms of Service" />
                  </Link>{" "}
                  <Translate text="and our" />{" "}
                  <Link
                    sx={{
                      color: theme.palette.lightGreen,
                      textDecoration: "none",
                    }}
                    onClick={() =>
                      dispatch(
                        setModalState({
                          isOpen: true,
                          type: "policy",
                          previousState: "signup",
                        })
                      )
                    }
                  >
                    <Translate text="Privacy Statement" />
                  </Link>
                </Typography>
              }
            />
          </FormGroup>
        </>
      ) : (
        <Box sx={{ minHeight: "90px" }} />
      )}

      <ButtonsGroup
        text="Complete"
        handleNext={handleNext}
        disabled={
          !useDebounceValue ||
          !isChecked ||
          !!errorMessage ||
          !email ||
          !userData.recaptcha_token
        }
        handleBack={handleBack}
        sx={{ nextBtn: { mt: { xs: 2, lg: 3 } } }}
      />

      <RecaptchaToken />
    </EmailCompBox>
  );
};
