import React from "react";
import { Typography, useTheme } from "@mui/material";
import { dialogContent } from "../dialog_data/dialogContent";

export const DialogSubtitle = ({ text }) => {
  const theme = useTheme();

  return (
    <Typography
      variant="Body1"
      sx={{
        mt: 3,
        display: "block",
        fontWeight: "600",
        color: theme.palette.darkGrey,
        textAlign: "left",
      }}
    >
      {dialogContent[text]}
    </Typography>
  );
};
