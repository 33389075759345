import React, { useEffect } from "react";
import { Box, styled, useTheme, useMediaQuery } from "@mui/material";
import { SubTitleComp } from "../common/SubTitleComp";
import { TextComp } from "../common/TextComp";
import { ButtonNext } from "../common/ButtonNext";
import { GengerLookingFor } from "../stepsComps/GengerLookingFor";
import { ImageComp } from "../common/ImageComp";
import { ModalComp } from "../common/ModalComp";
import { useDispatch } from "react-redux";
import { setModalState } from "../../features/modalStateSlice";
import { addField } from "../../features/userDataSlice";

const FormGenderLookingForBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  background: "#fff",
  marginTop: "60%",
  borderRadius: "16px",
  padding: "24px 16px 28px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "-20px",
  boxShadow: "0px 0px 24px 0px #00000029",

  [theme.breakpoints.up("md")]: {
    marginBottom: "32px",
    padding: "32px 32px 48px",
    width: "490px",
    marginTop: "7%",
    marginLeft: "8%",
  },

  [theme.breakpoints.up("lg")]: {
    width: "590px",
  },
}));

export const FormGenderLookingFor = ({ sx }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreenMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreenLg = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    dispatch(addField({ gender: "Male", looking_for: "Female" }));
  }, [dispatch]);

  const handleNext = () => {
    dispatch(
      setModalState({
        isOpen: true,
        type: "signup",
        previousState: "",
      })
    );
  };

  return (
    <FormGenderLookingForBox sx={sx}>
      <ImageComp
        imgSrc="logo"
        sx={{
          margin: { xs: "0 auto 24px", md: "0 auto 34px", lg: "0 auto 40px" },
          width: { xs: "150px", md: "202px", lg: "254px" },
          height: "auto",
        }}
      />

      <SubTitleComp text="gender_title" sx={{ mb: { xs: "4px", md: 1 } }} />

      <TextComp
        variant={
          isSmallScreenMd
            ? "Body4"
            : isSmallScreenLg
            ? "Subtitle3"
            : "Subtitle1"
        }
        text="gender_form_text"
        sx={{ mb: { xs: "18px", md: "28px", lg: 4 }, fontWeight: "500" }}
      />

      <GengerLookingFor
        type="gender"
        option1="male"
        option2="female"
        sx={{ mb: { xs: "18px", md: 3 } }}
      />

      <GengerLookingFor
        type="looking_for"
        option1="female"
        option2="male"
        sx={{ mb: { xs: "18px", md: "28px", lg: 4 } }}
      />

      <ButtonNext
        text="Try us for free"
        onClick={handleNext}
        sx={{ height: { xs: "48px", lg: "58px" }, width: "100%", m: { xs: 0 } }}
      />

      <ModalComp />
    </FormGenderLookingForBox>
  );
};
