import React from "react";
import { Box, styled, useTheme, useMediaQuery } from "@mui/material";
import { useDispatch } from "react-redux";
import { setModalState } from "../../features/modalStateSlice";
import { TextTranslComp } from "./TextTranslComp";

const HaveAccountBox = styled(Box)(({ sx }) => ({
  display: "flex",
  justifyContent: "center",
  ...sx,
}));

export const HaveAccount = ({ sx }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isSmallScreenMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <HaveAccountBox sx={sx}>
      <TextTranslComp
        variant={isSmallScreenMd ? "Body4" : "Body1"}
        text={`have account`}
      />
      <TextTranslComp
        component="a"
        href="https://intimflorts.com?open=login"
        variant={isSmallScreenMd ? "Body4" : "Body1"}
        sx={{
          textDecoration: "none",
          cursor: "pointer",
          color: theme.palette.orange,
          ml: 1,
        }}
        text="log In"
        onClick={() =>
          dispatch(
            setModalState({
              isOpen: false,
              type: "",
              previousState: "",
            })
          )
        }
      />
    </HaveAccountBox>
  );
};
