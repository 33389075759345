import React from "react";
import { Box } from "@mui/material";
import { DialogParagraph } from "./dialog_components/DialogParagraph";
import { DialogSubtitle } from "./dialog_components/DialogSubtitle";
import { DialogUnorderList } from "./dialog_components/DialogUnorderList";
import { cookieFormData } from "./dialog_data/dialogData";

export const CookiePolicyDialog = () => {
  return (
    <Box
      sx={{ padding: "10px 24px 24px", userSelect: "none" }}
      onContextMenu={(e) => e.preventDefault()}
    >
      <DialogParagraph text="cookie_statement_content_1" />
      <DialogSubtitle text="cookie_statement_content_2" />
      <DialogParagraph text="cookie_statement_content_3" />
      <DialogSubtitle text="cookie_statement_content_4" />
      <DialogParagraph text="cookie_statement_content_5" />
      <DialogUnorderList arrayData={cookieFormData} sx={{ margin: "20px 0" }} />
      <DialogParagraph text="cookie_statement_content_8" />
      <DialogSubtitle text="cookie_statement_content_9" />
      <DialogParagraph text="cookie_statement_content_10" />
      <DialogSubtitle text="cookie_statement_content_11" />
      <DialogParagraph text="cookie_statement_content_12" />
      <DialogSubtitle text="cookie_statement_content_13" />
      <DialogParagraph text="cookie_statement_content_14" />
      <DialogSubtitle text="cookie_statement_content_15" />
      <DialogParagraph text="cookie_statement_content_16" />
    </Box>
  );
};
