import React, { useEffect, useState } from "react";
import { Box, useTheme, styled, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { back, next } from "../../features/activeStepSlice";
import axios from "axios";
import { InputComp } from "../common/InputComp";
import { ErrorMessage } from "../common/ErrorMessage";
import { useDebounce } from "../../hooks/useDebounce";
import { addErrorMessage } from "../../features/errorMessageSlice";
import { addField } from "../../features/userDataSlice";
import { ButtonsGroup } from "../common/ButtonsGroup";
import { TextTranslComp } from "../common/TextTranslComp";

const UserNameCompBox = styled(Box)({
  width: "100%",
});

export const Username = () => {
  const userData = useSelector((state) => state.userData?.value);
  const [username, setUsername] = useState(userData.username || "");
  const apiUrl = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.errorMessage?.value);
  const regex = /^[_&?!#0-9a-zA-Z\u00c0-\u017e]{3,12}$/;
  const message = "usernameErrMessFE";
  const useDebounceValue = useDebounce(username, 500, regex, message);
  const theme = useTheme();
  const isLoading = useSelector((state) => state.isLoading.value);
  const isSmallScreenMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (e) => {
    const value = e.target.value;
    if (value.length < 13) {
      setUsername(value);
    }
    dispatch(addErrorMessage(""));
  };

  useEffect(() => {
    if (useDebounceValue || !errorMessage) {
      dispatch(addField({ username: useDebounceValue }));
    } else {
      dispatch(addField({ username: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useDebounceValue]);

  const fetchData = async () => {
    try {
      const response = await apiUrl.post(
        `/registration/start`,
        {
          username: useDebounceValue,
          recaptcha_token: null,
        },
        {
          params: {
            site_key: "no01",
          },
        }
      );
      if (response?.data.Status === "ok") {
        sessionStorage.setItem("user_id", response?.data.Data);
        dispatch(next());
        dispatch(addErrorMessage(""));
      }
    } catch (error) {
      if (error?.response?.data.Status === "fail") {
        dispatch(addErrorMessage(error?.response?.data?.Error?.message));
        localStorage.removeItem("uid");
      }
    }
  };

  const handleNext = () => {
    if (useDebounceValue) {
      fetchData();
    }
  };

  const handleBack = () => {
    dispatch(back());
    dispatch(addErrorMessage(""));
  };

  return (
    <UserNameCompBox className="userBox">
      {!isLoading ? (
        <>
          {!errorMessage ? (
            <TextTranslComp
              variant={isSmallScreenMd ? "Body6" : "Body3"}
              sx={{ mb: { xs: "12px", md: "16px" } }}
              text="usernameText"
            />
          ) : (
            <ErrorMessage errorMessage={errorMessage} />
          )}

          <InputComp
            name="username"
            value={username}
            handleChange={handleChange}
            placeholder="usernamePlaceholder"
            typeInput="text"
            isValid={!useDebounceValue || !!errorMessage}
            autoFocus={true}
          />
        </>
      ) : (
        <Box sx={{ minHeight: "90px" }} />
      )}

      <ButtonsGroup
        text="Next"
        handleNext={handleNext}
        disabled={!useDebounceValue || !!errorMessage}
        handleBack={handleBack}
        sx={{ nextBtn: { mt: { xs: 2, lg: 3 } } }}
      />
    </UserNameCompBox>
  );
};
