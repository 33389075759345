import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  styled,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { ErrorMessage } from "../common/ErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../features/userDataSlice";
import { addErrorMessage } from "../../features/errorMessageSlice";
import { back, next } from "../../features/activeStepSlice";
import { useDebounce } from "../../hooks/useDebounce";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { InputComp } from "../common/InputComp";
import { ButtonsGroup } from "../common/ButtonsGroup";
import { TextTranslComp } from "../common/TextTranslComp";

const PasswordCompBox = styled(Box)({
  width: "100%",
});

export const Password = () => {
  const userData = useSelector((state) => state.userData?.value);
  const [password, setPassword] = useState(userData.password || "");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.errorMessage?.value);
  const regex = /^(?=.*\d).{6,20}$/;
  const message = "passwordErrMessFE";
  const useDebounceValue = useDebounce(password, 1000, regex, message);
  const theme = useTheme();
  const isLoading = useSelector((state) => state.isLoading.value);
  const isSmallScreenMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (e) => {
    const value = e.target.value;
    if (value.length < 21) {
      setPassword(value);
      dispatch(addErrorMessage(""));
    }
  };

  useEffect(() => {
    if (useDebounceValue || !errorMessage) {
      dispatch(addField({ password: useDebounceValue }));
    } else {
      dispatch(addField({ password: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useDebounceValue]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleNext = () => {
    if (useDebounceValue && password) {
      try {
        dispatch(next());
        dispatch(addErrorMessage(""));
      } catch (error) {
        dispatch(addErrorMessage(error?.response?.data?.Error?.message));
      }
    }
  };

  const handleBack = () => {
    dispatch(back());
    dispatch(addErrorMessage(""));
  };

  return (
    <PasswordCompBox className="userBox">
      {!isLoading ? (
        <>
          {!errorMessage ? (
            <TextTranslComp
              variant={isSmallScreenMd ? "Body6" : "Body3"}
              sx={{ mb: { xs: "12px", md: "16px" } }}
              text="passwordText"
            />
          ) : (
            <ErrorMessage errorMessage={errorMessage} />
          )}

          <InputComp
            name="passwordMain"
            value={password}
            handleChange={handleChange}
            placeholder="passwordPlaceholder"
            typeInput={showPassword ? "text" : "password"}
            isValid={!useDebounceValue || !!errorMessage}
            extraComp={
              <IconButton onClick={togglePasswordVisibility}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            }
            autoFocus={true}
          />
        </>
      ) : (
        <Box sx={{ minHeight: "90px" }} />
      )}

      <ButtonsGroup
        text="Next"
        handleNext={handleNext}
        disabled={!useDebounceValue || !password}
        handleBack={handleBack}
        sx={{ nextBtn: { mt: { xs: 2, lg: 3 } } }}
      />
    </PasswordCompBox>
  );
};
