import React, { useEffect } from "react";
import {
  Box,
  Stack,
  styled,
  useTheme,
  useMediaQuery,
  keyframes,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { TextComp } from "./TextComp";
import { ImageWithStatus } from "./ImageWithStatus";
import { setIsLoading } from "../../features/isLoadingSlice";

const QuestionCompStack = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  marginBottom: "24px",

  [theme.breakpoints.up("md")]: {
    marginBottom: "32px",
  },

  [theme.breakpoints.up("lg")]: {
    marginBottom: "40px",
  },
}));

const QuestionTextBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "8px 16px",
  borderRadius: "0px 12px 12px 12px",
  background: theme.palette.lightGrey,
  minWidth: "240px",
  boxSizing: "border-box",
  minHeight: "40px",

  [theme.breakpoints.up("md")]: {
    minHeight: "48px",
    padding: "11px 32px",
  },
}));

const dotAnimation = keyframes`
  from {background-color: #1e1e1ecf}
  to {background-color: #c1c1c1}
  `;

const DotBox = styled(Box)(({ time }) => ({
  width: "5px",
  height: "5px",
  borderRadius: "50%",
  animation: `${dotAnimation} ${time}s linear infinite alternate`,
  margin: "0 2px",
  background: "#333",
}));

export const QuestionComp = () => {
  const activeStep = useSelector((state) => state.activeStep.value);
  const theme = useTheme();
  const isSmallScreenMd = useMediaQuery(theme.breakpoints.down("md"));
  const isLoading = useSelector((state) => state.isLoading.value);
  const dispatch = useDispatch();

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(setIsLoading(false));
    }, 1000);
    return () => {
      clearTimeout(timeout);
      dispatch(setIsLoading(true));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  return (
    <QuestionCompStack>
      <ImageWithStatus />

      <QuestionTextBox>
        {isLoading ? (
          <Box sx={{ display: "flex" }}>
            <DotBox time={1} />
            <DotBox time={1.1} />
            <DotBox time={1.2} />
          </Box>
        ) : (
          <TextComp
            component="p"
            variant={isSmallScreenMd ? "Body4" : "Body1"}
            text={`step${activeStep}_question`}
          />
        )}
      </QuestionTextBox>
    </QuestionCompStack>
  );
};
