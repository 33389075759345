import React from "react";
import {
  Box,
  Divider,
  Typography,
  styled,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Translate } from "react-translated";
import { TextTranslComp } from "../common/TextTranslComp";
import { linksData } from "../../dataUI/homeData";
import { useDispatch } from "react-redux";
import { setModalState } from "../../features/modalStateSlice";
import { ModalComp } from "../common/ModalComp";

const FooterBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "28px 16px",
  background: "#FFF8F1",
  minHeight: "180px",

  [theme.breakpoints.up("md")]: {
    padding: "40px 0 28px",
  },
}));

const FooterLinksBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
});

export const Footer = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreenMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <FooterBox component="footer">
      <FooterLinksBox>
        {linksData?.map((link) => (
          <TextTranslComp
            text={link.type}
            variant={isSmallScreenMd ? "Body3" : "Body1"}
            component="a"
            sx={{
              cursor: "pointer",
              color: "#212B36",
              px: 1,
              borderRight: "1px solid #212B36",
              wordBreak: "normal",

              ":last-child": { borderRight: "none" },
            }}
            onClick={() =>
              dispatch(
                setModalState({
                  isOpen: true,
                  type: link.type,
                  previousState: "",
                })
              )
            }
            key={link.id}
          />
        ))}
      </FooterLinksBox>
      <Divider
        sx={{ mt: { xs: 4, md: 5 }, mb: { xs: "18px", md: 3 }, width: "100%" }}
      />
      <Typography variant="Body3">
        &copy; {new Date().getFullYear()} {process.env.REACT_APP_TITLE} |{" "}
        <Translate text="footer_rights_reserved" />
      </Typography>
      <ModalComp />
    </FooterBox>
  );
};
