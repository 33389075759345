import React from "react";
import { ButtonNext } from "./ButtonNext";
import { Box, styled } from "@mui/material";
import { ButtonBack } from "./ButtonBack";

const ButtonsGroupBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row-reverse",
  justifyContent: "space-between",
  boxShadow: "0px 0px 24px 0px #00000029",
  width: "100%",
  zIndex: "100",
  position: "fixed",
  bottom: "0",
  left: "0",
  padding: "0 16px",

  [theme.breakpoints.up("md")]: {
    boxShadow: "none",
    position: "static",
    padding: "0",
    marginTop: "4px",
  },

  [theme.breakpoints.up("lg")]: {
    flexDirection: "column",
  },
}));

export const ButtonsGroup = ({
  text,
  handleNext,
  disabled,
  handleBack,
  sx = {},
}) => {
  return (
    <ButtonsGroupBox sx={{ ...sx.buttonsBox }}>
      <ButtonNext
        text={text}
        onClick={handleNext}
        disabled={disabled}
        sx={{ ...sx?.nextBtn }}
      />
      <ButtonBack onClick={handleBack} sx={{ ...sx?.backBtn }} />
    </ButtonsGroupBox>
  );
};
