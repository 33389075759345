import React from "react";
import { Typography, useTheme, useMediaQuery } from "@mui/material";
import { Translate } from "react-translated";

export const SubTitleSmallComp = ({ text = "", sx = {}, component = "h4" }) => {
  const theme = useTheme();
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;
  const isSmallScreenMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreenLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Typography
      variant={
        isSmallScreenMd
          ? "Subtitle4"
          : isSmallScreenLg
          ? "Subtitle2"
          : "Subtitle3"
      }
      sx={{
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
        display: "block",
        ...sx,
      }}
      component={component}
    >
      <Translate text={`${text}_${dynamicType}`} />
    </Typography>
  );
};
