import React from "react";
import { Typography } from "@mui/material";
import { dialogContent } from "../dialog_data/dialogContent";

export const DialogParagraph = ({ text, sx }) => {
  return (
    <Typography
      variant="Body5"
      component="p"
      sx={{
        ...sx,
        my: 1 / 2,
        mb: 1,
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
      }}
    >
      {dialogContent[text]}
    </Typography>
  );
};
