import React from "react";
import { Button, styled, useTheme, useMediaQuery } from "@mui/material";
import { TextComp } from "./TextComp";

const ButtonNextStyles = styled(Button)(({ theme }) => ({
  height: "48px",
  width: "48%",
  margin: "16px 0",
  borderRadius: "16px",
  background: theme.palette.orange,
  textTransform: "none",
  maxWidth: "100%",
  boxSizing: "border-box",
  padding: "16px",

  "&:hover": {
    backgroundColor: "rgba(247, 100, 72, 0.9)",
  },

  [theme.breakpoints.up("lg")]: {
    margin: "0 auto 16px",
    width: "100%",
  },
}));

export const ButtonNext = ({
  text,
  disabled = false,
  sx = {},
  onClick = null,
}) => {
  const theme = useTheme();
  const isSmallScreenMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <ButtonNextStyles onClick={onClick} disabled={disabled} sx={sx}>
      <TextComp
        text={text}
        variant={isSmallScreenMd ? "Subtitle4" : "Subtitle2"}
        sx={{
          color: disabled ? theme.palette.darkOrange : theme.palette.white,
        }}
      />
    </ButtonNextStyles>
  );
};
