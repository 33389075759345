import React from "react";
import { Box, styled, useTheme, useMediaQuery } from "@mui/material";
import { ButtonComp } from "../common/ButtonComp";
import { FormGenderLookingFor } from "./FormGenderLookingFor";

const HeaderBox = styled(Box)(({ theme, homepagebg }) => ({
  backgroundImage: `url(${homepagebg})`,
  backgroundSize: "cover",
  backgroundPosition: "top",
  width: "100%",
  height: "65vw",
  backgroundRepeat: "no-repeat",

  [theme.breakpoints.up("md")]: {
    height: "auto",
    minHeight: "100vh",
    backgroundPosition: "center",
  },
}));

export const Header = () => {
  const theme = useTheme();
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;
  const isSmallScreenMd = useMediaQuery(theme.breakpoints.down("md"));
  const homepagebg = require(`./../../images/home_page_bg_${
    isSmallScreenMd ? "mobile" : "web"
  }_${dynamicType}.png`);

  return (
    <HeaderBox homepagebg={homepagebg}>
      <ButtonComp
        text="log In"
        href="https://intimflorts.com?open=login"
        component="a"
        sx={{
          borderStyles: {
            float: "right",
            border: `2px solid ${theme.palette.orange}`,
            justifyContent: "center",
            margin: { xs: "16px", md: "32px 8% 32px 0" },
            width: { xs: "120px", md: "220px" },

            "&:hover": {
              backgroundColor: "#fffffff2",
            },
          },
          textStyles: { color: theme.palette.orange },
        }}
      />
      {!isSmallScreenMd && <FormGenderLookingFor />}
    </HeaderBox>
  );
};
