import React from "react";
import { Box, styled, useTheme, useMediaQuery } from "@mui/material";
import { Header } from "./home/Header";
import { InformationBlock } from "./home/InformationBlock";
import { FindBrandName } from "./home/FindBrandName";
import { WhyBrandName } from "./home/WhyBrandName";
import { LanguageMatters } from "./home/LanguageMatters";
import { HowHelpsWin } from "./home/HowHelpsWin";
import { MakesEasyForYou } from "./home/MakesEasyForYou";
import { Footer } from "./home/Footer";
import { FormGenderLookingFor } from "./home/FormGenderLookingFor";

const HomePageBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  justifyContent: "center",
});

export const HomePage = () => {
  const theme = useTheme();
  const isSmallScreenMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <HomePageBox>
      <Header />
      {isSmallScreenMd && <FormGenderLookingFor sx={{ mt: "-4%" }} />}
      <InformationBlock />
      <FindBrandName />
      <WhyBrandName />
      <LanguageMatters />
      <HowHelpsWin />
      <MakesEasyForYou />
      <Footer />
    </HomePageBox>
  );
};
