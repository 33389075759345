import React from "react";
import { Box, styled } from "@mui/material";
import { TitleComp } from "../common/TitleComp";
import { findBrandNameData } from "../../dataUI/homeData";
import { InfoCart } from "../common/InfoCart";

const FindBrandNameBox = styled(Box)(({ theme }) => ({
  width: "100%",
  margin: "0 auto",
  padding: "0 16px",

  [theme.breakpoints.up("md")]: {
    width: "94%",
    margin: "0 auto 80px",
    padding: 0,
  },

  [theme.breakpoints.up("lg")]: {
    margin: "0 auto 100px",
    width: "85%",
  },
}));

const FindBrandNameInfoCartsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

export const FindBrandName = () => {
  return (
    <FindBrandNameBox>
      <TitleComp text="find_brand_name_title" />
      <FindBrandNameInfoCartsBox>
        {findBrandNameData?.map((item) => (
          <InfoCart
            item={item}
            key={item?.id}
            sx={{
              boxStyles: {
                width: { xs: "100%", md: "30%", lg: "28%" },
                mb: { xs: 5, md: 0 },
              },
              imgWrappStyles: {
                minHeight: { xs: "200px", md: "240px" },
                mb: { xs: 2, md: 3, lg: 4 },
              },
            }}
          />
        ))}
      </FindBrandNameInfoCartsBox>
    </FindBrandNameBox>
  );
};
