import React from "react";
import { Box, styled } from "@mui/material";
import { TitleComp } from "../common/TitleComp";
import { languageMattersData } from "../../dataUI/homeData";
import { InfoCart } from "../common/InfoCart";

const LanguageMattersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  margin: "0 auto",
  padding: "0 16px",

  [theme.breakpoints.up("md")]: {
    width: "94%",
    margin: "0 auto 80px",
    padding: 0,
  },

  [theme.breakpoints.up("lg")]: {
    margin: "0 auto 100px",
    width: "85%",
  },
}));

const LanguageMattersInfoCartsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

export const LanguageMatters = () => {
  return (
    <LanguageMattersBox>
      <TitleComp text="language_matters_title" />
      <LanguageMattersInfoCartsBox>
        {languageMattersData?.map((item) => (
          <InfoCart
            item={item}
            key={item?.id}
            sx={{
              boxStyles: {
                width: { xs: "100%", md: "30%", lg: "28%" },
                mb: { xs: 5, md: 0 },
                flexDirection: { xs: "column-reverse", md: "column" },
              },
              imgWrappStyles: {
                width: { xs: "92%", md: "90%", lg: "70%" },
                margin: "0 auto 32px",
                minHeight: { xs: "auto", md: "320px", lg: "360px" },
              },
              textStyles: { mb: 3 },
            }}
          />
        ))}
      </LanguageMattersInfoCartsBox>
    </LanguageMattersBox>
  );
};
