import React, { useState, useEffect } from "react";
import {
  Box,
  InputBase,
  MenuItem,
  styled,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { next } from "../../features/activeStepSlice";
import { ErrorMessage } from "../common/ErrorMessage";
import axios from "axios";
import { Translator } from "react-translated";
import { addErrorMessage } from "../../features/errorMessageSlice";
import { addField } from "../../features/userDataSlice";
import { setModalState } from "../../features/modalStateSlice";
import { ButtonsGroup } from "../common/ButtonsGroup";
import { TextTranslComp } from "../common/TextTranslComp";

const LocationCompBox = styled(Box)({
  position: "relative",
  width: "100%",
});

const SearchBox = styled(Box)({
  position: "relative",
  borderRadius: "16px",
  width: "100%",
  border: "1px solid #F76448",
  background: "#fff",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 16px",
  boxSizing: "border-box",
  height: "48px",
});

const LocationBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  background: "white",
  width: "100%",
  zIndex: "101",
  maxHeight: "250px",
  overflowY: "auto",
  borderRadius: "10px",
  boxShadow: "0  0 4px #cacaca",

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#cacaca",
    border: "4px solid transparent",
    borderRadius: "8px",
    backgroundClip: "padding-box",
  },

  "&::-webkit-scrollbar": {
    width: "16px",
  },

  [theme.breakpoints.up("sm")]: {
    maxHeight: "200px",
    background: "#fffffffa",
  },
}));

export const Location = () => {
  const userData = useSelector((state) => state.userData?.value);
  const apiUrl = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  const [searchVal, setSearchVal] = useState("");
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState(userData.location || "");
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.errorMessage?.value);
  const theme = useTheme();
  const isLoading = useSelector((state) => state.isLoading.value);
  const isSmallScreenMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchVal(value);
    setLocation("");
    dispatch(addErrorMessage(""));
  };

  const handleLocationSelect = (item) => {
    setLocation(item);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiUrl.get("/registration/locations", {
          params: {
            search: searchVal,
            skip: 0,
            limit: 10,
            site_key: "no01",
          },
        });
        if (response?.data?.Data?.length > 0) {
          setLocations(response?.data?.Data);
        } else {
          setLocations([]);
          dispatch(addErrorMessage("locationErrMessFE"));
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (searchVal !== "") {
      fetchData();
    } else {
      setLocations([]);
      dispatch(addErrorMessage(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVal]);

  useEffect(() => {
    if (location) {
      dispatch(addField({ location: location }));
    } else {
      dispatch(addField({ location: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleNext = () => {
    if (location) {
      dispatch(next());
    }
  };

  const handleBack = () => {
    dispatch(setModalState({ isOpen: false, type: "", previousState: "" }));
    dispatch(addErrorMessage(""));
  };

  return (
    <LocationCompBox>
      {!isLoading ? (
        <>
          {!errorMessage ? (
            <TextTranslComp
              variant={isSmallScreenMd ? "Body6" : "Body3"}
              sx={{ mb: { xs: "12px", md: "16px" } }}
              text="locationText"
            />
          ) : (
            <ErrorMessage errorMessage={errorMessage} />
          )}

          <SearchBox>
            <Translator>
              {({ translate }) => (
                <InputBase
                  placeholder={translate({
                    text: `locationPlaceholder`,
                  })}
                  inputProps={{ "aria-label": "search" }}
                  onChange={handleSearchChange}
                  sx={{ width: "100%", fontSize: "16px" }}
                  value={location || searchVal}
                  autoFocus
                />
              )}
            </Translator>
            <SearchIcon sx={{ color: "#B2B3B5", fontSize: "22px" }} />
          </SearchBox>

          {!location && searchVal && (
            <LocationBox>
              {locations.map((item) => (
                <MenuItem
                  id="location-menu-item"
                  sx={{
                    fontSize: "16px",
                    lineHeight: "20px",
                    minHeight: "auto",
                  }}
                  key={item}
                  onClick={() => handleLocationSelect(item)}
                >
                  {item}
                </MenuItem>
              ))}
            </LocationBox>
          )}
        </>
      ) : (
        <Box sx={{ minHeight: "90px" }} />
      )}

      <ButtonsGroup
        text="Next"
        handleNext={handleNext}
        disabled={!location}
        handleBack={handleBack}
        sx={{ nextBtn: { mt: { xs: 2, lg: 3 } } }}
      />
    </LocationCompBox>
  );
};
