import React from "react";
import {
  IconButton,
  Dialog,
  DialogContent,
  useTheme,
  Box,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { setModalState } from "../../features/modalStateSlice";
import { DialogContentType } from "../../dialog/DialogContentType";

const DialogCloseBtn = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "20px 10px 0 24px",
});

const DialogContentStyle = styled(DialogContent)({
  padding: "0",

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#cacaca",
    border: "4px solid transparent",
    borderRadius: "8px",
    backgroundClip: "padding-box",
  },

  "&::-webkit-scrollbar": {
    width: "16px",
  },
});

export const ModalComp = () => {
  const modalState = useSelector((state) => state.modalState);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreenMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreenXl = useMediaQuery(theme.breakpoints.down("xl"));
  const dialogWidth = isSmallScreenMd
    ? "100%"
    : modalState.type === "terms" ||
      modalState.type === "policy" ||
      modalState.type === "cookie"
    ? "94%"
    : !isSmallScreenXl
    ? "60%"
    : "80%";

  const titleText = {
    terms: "General Terms of Service",
    policy: "Privacy Statement",
    cookie: "Cookie Policy",
  };

  const handleClose = () => {
    if (modalState.previousState === "signup") {
      dispatch(
        setModalState({ isOpen: true, type: "signup", previousState: "" })
      );
    } else {
      dispatch(setModalState({ isOpen: false, type: "", previousState: "" }));
    }
  };

  return (
    <Dialog
      open={modalState.isOpen}
      onClose={handleClose}
      fullWidth
      fullScreen={isSmallScreenMd}
      maxWidth={false}
      PaperProps={{
        sx: {
          width: dialogWidth,
          margin: "0 auto",
          boxSizing: "border-box",
          borderRadius: {
            xs: "0px",
            md: "16px",
          },
          pb: modalState.type !== "signup" && 3,
        },
      }}
    >
      {(modalState.type === "terms" ||
        modalState.type === "policy" ||
        modalState.type === "cookie") && (
        <DialogCloseBtn>
          {titleText[modalState.type] && (
            <Typography
              component="h2"
              variant="Subtitle1"
              sx={{ color: theme.palette.darkGrey }}
            >
              {titleText[modalState.type]}
            </Typography>
          )}
          <IconButton onClick={handleClose} sx={{ color: "primary.grey" }}>
            <CloseIcon sx={{ fontSize: "24px" }} />
          </IconButton>
        </DialogCloseBtn>
      )}

      <DialogContentStyle>
        <DialogContentType type={modalState.type} />
      </DialogContentStyle>
    </Dialog>
  );
};
