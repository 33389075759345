import React from "react";
import { TextComp } from "./TextComp";
import { Button, styled, useTheme, useMediaQuery } from "@mui/material";

const ButtonBackStyles = styled(Button)(({ sx, theme }) => ({
  height: "48px",
  border: `2px solid ${theme.palette.black}`,
  width: "48%",
  borderRadius: "16px",
  padding: "16px",
  background: "none",
  textTransform: "none",
  maxWidth: "100%",
  boxSizing: "border-box",
  margin: "16px 0",
  display: "flex",
  ...sx,

  "&:hover": {
    backgroundColor: "#fff",
  },

  [theme.breakpoints.up("lg")]: {
    height: "auto",
    margin: "0 auto",
    display: "block",
    padding: 0,
    border: "none",
    width: "auto",
    borderRadius: "none",
  },
}));

export const ButtonBack = ({ onClick = null, sx = {} }) => {
  const theme = useTheme();
  const isSmallScreenMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <ButtonBackStyles sx={{ ...sx.backButtonBox }} onClick={onClick}>
      <TextComp
        text="Back"
        variant={isSmallScreenMd ? "Subtitle4" : "Subtitle2"}
        sx={{
          width: "fit-content",
          cursor: "pointer",
          margin: "0 auto",
          userSelect: "none",
          ...sx?.text,
        }}
      />
    </ButtonBackStyles>
  );
};
