import React from "react";
import { useTheme, useMediaQuery } from "@mui/material";
import { Typography } from "@mui/material";
import { Translate } from "react-translated";

export const TitleComp = ({ text = "", sx = {} }) => {
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;
  const theme = useTheme();
  const isSmallScreenMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreenLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Typography
      variant={
        isSmallScreenMd
          ? "Subtitle1"
          : isSmallScreenLg
          ? "Headline2"
          : "Headline1"
      }
      component="h2"
      sx={{
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
        textAlign: "center",
        mb: { xs: 3, md: "28px", lg: 5 },
        fontWeight: "600",
        ...sx,
      }}
    >
      <Translate text={`${text}_${dynamicType}`} />
    </Typography>
  );
};
