import { createSlice } from "@reduxjs/toolkit";

export const modalStateSlice = createSlice({
  name: "modalState",
  initialState: {
    isOpen: false,
    type: "",
    previousState: "",
  },
  reducers: {
    setModalState: (state, action) => {
      state.isOpen = action.payload.isOpen;
      state.type = action.payload.type;
      state.previousState = action.payload.previousState;
    },
  },
});

export const { setModalState } = modalStateSlice.actions;

export default modalStateSlice.reducer;
