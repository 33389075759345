import React from "react";
import { Box, styled, keyframes } from "@mui/material";
import { ImageComp } from "./ImageComp";

const ImageOnlineStatus = styled(Box)(() => ({
  position: "relative",
  marginRight: "16px",
}));

const OnlineStatusBox = styled(Box)(({ theme }) => ({
  width: "8px",
  height: "8px",
  background: theme.palette.lightGreen,
  borderRadius: "50%",
  position: "absolute",
  boxShadow: "0 0 5px #fff",
  animation: `${pulse} 1s infinite`,
  border: "1px solid #fff",
  left: "30px",
  top: "30px",

  [theme.breakpoints.up("md")]: {
    left: "37px",
    top: "37px",
  },
}));

const pulse = keyframes`
  0% {transform: scale(1)}
  50% {transform: scale(1.05); opacity: 0.9}
  100% {transform: scale(1)}
`;

export const ImageWithStatus = () => {
  return (
    <ImageOnlineStatus>
      <ImageComp
        imgSrc="questionImg"
        sx={{
          width: { xs: "40px", md: "48px" },
          height: { xs: "40px", md: "48px" },
        }}
      />
      <OnlineStatusBox />
    </ImageOnlineStatus>
  );
};
