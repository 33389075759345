import React from "react";
import { Button, styled, useTheme, useMediaQuery } from "@mui/material";
import { TextTranslComp } from "./TextTranslComp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useSelector } from "react-redux";

const ButtonCompStles = styled(Button)(({ theme }) => ({
  height: "48px",
  width: "48%",
  borderRadius: "16px",
  textTransform: "none",
  border: `1px solid ${theme.palette.grey}`,
  background: "#fff",
  justifyContent: "flex-start",
  padding: "16px",

  [theme.breakpoints.up("lg")]: {
    height: "58px",
  },
}));

export const ButtonComp = ({
  text,
  disabled = false,
  sx = {},
  onClick = null,
  type = "",
  href,
  component,
}) => {
  const theme = useTheme();
  const isSmallScreenMd = useMediaQuery(theme.breakpoints.down("md"));
  const userData = useSelector((state) => state.userData?.value);

  const checkedVavlue =
    userData[type]?.toLocaleLowerCase() === text ? (
      <CheckCircleIcon
        sx={{
          color: theme.palette.orange,
          mr: "12px",
          width: { xs: "22px", md: "26px" },
          height: { xs: "22px", md: "26px" },
        }}
      />
    ) : (
      <RadioButtonUncheckedIcon
        sx={{
          color: theme.palette.orange,
          mr: "12px",
          width: { xs: "22px", md: "26px" },
          height: { xs: "22px", md: "26px" },
        }}
      />
    );

  return (
    <ButtonCompStles
      onClick={onClick}
      disabled={disabled}
      sx={sx?.borderStyles}
      component={component}
      href={href}
    >
      {(text === "male" || text === "female") && checkedVavlue}
      <TextTranslComp
        text={text}
        variant={isSmallScreenMd ? "Subtitle4" : "Subtitle2"}
        sx={{ ...sx?.textStyles }}
      />
    </ButtonCompStles>
  );
};
