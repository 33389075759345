import React from "react";
import { Box } from "@mui/material";
import { SubTitleSmallComp } from "../common/SubTitleSmallComp";
import { ButtonComp } from "../common/ButtonComp";
import { useDispatch } from "react-redux";
import { addField } from "../../features/userDataSlice";

export const GengerLookingFor = ({
  type = "gender",
  option1 = "male",
  option2 = "female",
  sx = {},
}) => {
  const dispatch = useDispatch();

  const handleChange = (selectedGender) => {
    dispatch(addField({ [type]: selectedGender }));
  };

  return (
    <Box sx={{ width: "100%" }}>
      <SubTitleSmallComp
        text={`${type}_subtitle`}
        sx={{ mb: { xs: "10px", md: 2 } }}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between", ...sx }}>
        <ButtonComp
          sx={{
            borderStyles: { width: "48%" },
            textStyles: {},
          }}
          onClick={() => handleChange(option1 === "male" ? "Male" : "Female")}
          text={option1}
          type={type}
        />
        <ButtonComp
          sx={{
            borderStyles: { width: "48%" },
            textStyles: {},
          }}
          onClick={() => handleChange(option2 === "female" ? "Female" : "Male")}
          text={option2}
          type={type}
        />
      </Box>
    </Box>
  );
};
