import React from "react";
import { Box, styled, useTheme, useMediaQuery } from "@mui/material";
import { TitleComp } from "../common/TitleComp";
import { TextComp } from "../common/TextComp";
import { ImageComp } from "../common/ImageComp";

const WhyBrandNameBox = styled(Box)(({ theme }) => ({
  background: "#FFF8F1",
  width: "100%",
  padding: "16px 16px 20px",
  marginBottom: "40px",

  [theme.breakpoints.up("md")]: {
    padding: "40px 0 60px",
    marginBottom: "80px",
  },

  [theme.breakpoints.up("lg")]: {
    marginBottom: "100px",
  },
}));

const WhyBrandNameContentWrapperBox = styled(Box)(({ theme }) => ({
  width: "100%",

  [theme.breakpoints.up("md")]: {
    width: "90%",
    margin: "0 auto",
  },

  [theme.breakpoints.up("lg")]: {
    width: "72%",
  },
}));

const WhyBrandNameContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "column-reverse",

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

const TextWrapper = styled(Box)(({ theme }) => ({
  width: "100%",

  [theme.breakpoints.up("md")]: {
    width: "48%",
  },
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
  width: "80%",
  marginBottom: "24px",

  [theme.breakpoints.up("md")]: {
    width: "48%",
    marginBottom: 0,
  },

  [theme.breakpoints.up("lg")]: {
    width: "42%",
  },
}));

export const WhyBrandName = () => {
  const theme = useTheme();
  const isSmallScreenLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <WhyBrandNameBox>
      <WhyBrandNameContentWrapperBox>
        <TitleComp text="why_brand_name_title" />
        <WhyBrandNameContentBox>
          <TextWrapper>
            <TextComp
              text="why_brand_name_text_1"
              variant={isSmallScreenLg ? "Body4" : "Body1"}
              sx={{ mb: 3 }}
            />
            <TextComp
              text="why_brand_name_text_2"
              variant={isSmallScreenLg ? "Body4" : "Body1"}
            />
          </TextWrapper>
          <ImageWrapper>
            <ImageComp imgSrc="why_brand_name_img" sx={{ width: "100%" }} />
          </ImageWrapper>
        </WhyBrandNameContentBox>
      </WhyBrandNameContentWrapperBox>
    </WhyBrandNameBox>
  );
};
