import { MenuItem } from "@mui/material";
import { Translate } from "react-translated";

const monthsNameArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const generateOptions = (num) => {
  const options = [];
  for (let i = 1; i <= num; i++) {
    const addedDay = i.toString().padStart(2, "0");
    options.push(
      <MenuItem key={i} value={addedDay} sx={{ minHeight: "auto" }}>
        {addedDay}
      </MenuItem>
    );
  }
  return options;
};

export const getMonthsName = () => {
  return monthsNameArray.map((item, idx) => (
    <MenuItem
      key={item}
      value={(idx + 1).toString().padStart(2, "0")}
      sx={{ minHeight: "auto" }}
    >
      <Translate text={item} />
    </MenuItem>
  ));
};

export const generateYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 80;
  const options = [];
  for (let year = currentYear - 18; year >= startYear; year--) {
    options.push(
      <MenuItem key={year} value={year} sx={{ minHeight: "auto" }}>
        {year}
      </MenuItem>
    );
  }
  return options;
};
