import React from "react";
import { Box, Typography } from "@mui/material";
import { dialogContent } from "../dialog_data/dialogContent";

export const DialogOrderList = ({ arrayData }) => {
  return arrayData?.map((item) => (
    <Box key={item.order} sx={{ display: "flex", mt: 1, ml: 1 }}>
      <Typography
        component="span"
        variant="Body5"
        sx={{ mr: 2, fontSize: "14px" }}
      >
        {item.order}
      </Typography>
      <Typography variant="Body5" component="p" sx={{ whiteSpace: "pre-wrap" }}>
        {dialogContent[`general_terms_of_service_content_${item.num}`]}
      </Typography>
    </Box>
  ));
};
