import React from "react";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { Translate } from "react-translated";

export const SubTitleComp = ({ text = "", sx = {}, component = "h3" }) => {
  const theme = useTheme();
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;
  const isSmallScreenMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreenLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Typography
      variant={
        isSmallScreenMd
          ? "Subtitle3"
          : isSmallScreenLg
          ? "Subtitle1"
          : "Headline2"
      }
      sx={{
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
        fontWeight: "600",
        ...sx,
      }}
      component={component}
    >
      <Translate text={`${text}_${dynamicType}`} />
    </Typography>
  );
};
