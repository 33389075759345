import React from "react";
import { Box, styled, useTheme } from "@mui/material";
import { InfoCart } from "../common/InfoCart";
import { informationBlockData } from "../../dataUI/homeData";

const InformationBlockBox = styled(Box)(({ theme }) => ({
  background: "#FFFFFFF5",
  width: "100%",
  minHeight: "290px",
  padding: "16px 16px 0",
  margin: "40px auto",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",

  [theme.breakpoints.up("md")]: {
    padding: "24px 32px 28px",
    borderRadius: "16px",
    border: `1px solid ${theme.palette.lightGrey}`,
    width: "94%",
    flexDirection: "row",
    margin: "60px auto 80px",
  },

  [theme.breakpoints.up("xl")]: {
    margin: "-145px auto 100px",
    width: "85%",
  },
}));

export const InformationBlock = () => {
  const theme = useTheme();

  return (
    <InformationBlockBox>
      {informationBlockData?.map((item) => (
        <InfoCart
          item={item}
          key={item?.id}
          sx={{
            boxStyles: {
              width: { xs: "100%", md: "22.5%" },
              borderRight: {
                xs: "none",
                md: `2px solid  ${theme.palette.lightGrey}`,
              },
              borderBottom: {
                xs: `2px solid  ${theme.palette.lightGrey}`,
                md: "none",
              },
              paddingRight: { xs: 0, md: "2.5%" },
              paddingBottom: { xs: "24px", md: "0" },
              marginBottom: { xs: "24px", md: "0" },

              ":last-child": {
                border: "none",
                p: 0,
                m: 0,
              },
            },
            imgWrappStyles: { mb: 2 },
            imgStyles: {
              width: { xs: "52px", lg: "64px" },
              height: { xs: "52px", lg: "64px" },
            },
            titleStyles: { textAlign: { xs: "center", md: "left" } },
            textStyles: { textAlign: { xs: "center", md: "left" } },
          }}
        />
      ))}
    </InformationBlockBox>
  );
};
