import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { back, next } from "../../features/activeStepSlice";
import { Box, styled, useTheme, useMediaQuery } from "@mui/material";
import { FormControlComp } from "../common/FormControlComp";
import { addField } from "../../features/userDataSlice";
import {
  generateOptions,
  generateYearOptions,
  getMonthsName,
} from "../../helpers/DateUtils";
import { ErrorMessage } from "../common/ErrorMessage";
import { addErrorMessage } from "../../features/errorMessageSlice";
import { ButtonsGroup } from "../common/ButtonsGroup";
import { TextTranslComp } from "../common/TextTranslComp";

const AgeDataBoxWrapper = styled(Box)({
  width: "100%",
});

const AgeDataBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

export const Age = () => {
  const userData = useSelector((state) => state.userData?.value);
  const errorMessage = useSelector((state) => state.errorMessage?.value);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isLoading = useSelector((state) => state.isLoading.value);
  const isSmallScreenMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(addField({ [name]: value }));
  };

  const handleNext = () => {
    if (userData.day && userData.month && userData.year) {
      dispatch(next());
    }
  };

  const handleBack = () => {
    dispatch(back());
    dispatch(addErrorMessage(""));
  };

  return (
    <AgeDataBoxWrapper>
      {!isLoading ? (
        <>
          {!errorMessage ? (
            <TextTranslComp
              variant={isSmallScreenMd ? "Body6" : "Body3"}
              sx={{ mb: { xs: "18px", md: "16px" } }}
              text="ageText"
            />
          ) : (
            <ErrorMessage errorMessage={errorMessage} />
          )}

          <AgeDataBox>
            <FormControlComp
              value={userData.day || ""}
              handleChange={handleChange}
              label="day"
              name="day"
              getOption={generateOptions(31)}
              labelId="day-label"
              id="day-select"
              sx={{ width: { xs: "24%", md: "28%" } }}
            />
            <FormControlComp
              value={userData.month || ""}
              handleChange={handleChange}
              label="month"
              name="month"
              getOption={getMonthsName()}
              labelId="month-label"
              id="month-select"
              sx={{ width: { xs: "38%", md: "34%" } }}
            />
            <FormControlComp
              value={userData.year || ""}
              handleChange={handleChange}
              label="year"
              name="year"
              getOption={generateYearOptions()}
              labelId="year-label"
              id="year-select"
              sx={{ width: { xs: "30%", md: "28%" }, marginBottom: 0 }}
            />
          </AgeDataBox>
        </>
      ) : (
        <Box sx={{ minHeight: "90px" }} />
      )}

      <ButtonsGroup
        text="Next"
        handleNext={handleNext}
        disabled={!userData.day || !userData.month || !userData.year}
        handleBack={handleBack}
        sx={{ nextBtn: { mt: { xs: 2, lg: 3 } } }}
      />
    </AgeDataBoxWrapper>
  );
};
