import React from "react";
import { Box, styled } from "@mui/material";

const ImgBox = styled(Box)(({ sx }) => ({
  display: "block",
  ...sx,
}));

export const ImageComp = ({ imgSrc = "", sx = {} }) => {
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <ImgBox
      component="img"
      src={require(`./../../images/${
        imgSrc === "logo" ? imgSrc : `${imgSrc}_${dynamicType}`
      }.png`)}
      alt="image"
      sx={sx}
    />
  );
};
