import React from "react";
import { FormControl, InputLabel, Select, styled } from "@mui/material";
import { Translate } from "react-translated";

const FormControlBox = styled(FormControl)(({ theme }) => ({
  border: `1px solid ${theme.palette.orange}`,
  color: theme.palette.black,
  borderRadius: "16px",
  outline: "none",
  height: "48px",
  width: "30%",
  boxSizing: "border-box",
  maxWidth: "100%",
}));

export const FormControlComp = ({
  value,
  handleChange,
  label,
  name,
  getOption = null,
  labelId,
  id,
  sx,
}) => {
  return (
    <FormControlBox fullWidth className="formControlBox" sx={sx}>
      <InputLabel id={id} sx={{ p: "0 5px" }}>
        <Translate text={label} />
      </InputLabel>
      <Select
        name={name}
        value={value}
        label={<Translate text={label} />}
        onChange={handleChange}
        labelId={labelId}
        id={id}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 240,
              background: "#fffffffa",
            },
          },
        }}
      >
        {getOption}
      </Select>
    </FormControlBox>
  );
};
