import React from "react";
import { Box, styled } from "@mui/material";
import { TitleComp } from "../common/TitleComp";
import { howHelpsWinData1, howHelpsWinData2 } from "../../dataUI/homeData";
import { HowHelpsWinInfoCartsNumber } from "../common/HowHelpsWinInfoCartsNumber";

const HowHelpsWinBox = styled(Box)(({ theme }) => ({
  background: "#FFF8F1",
  width: "100%",
  minHeight: "500px",
  padding: "16px 16px 0",
  marginBottom: "40px",

  [theme.breakpoints.up("md")]: {
    padding: "40px 0 0",
    marginBottom: "80px",
  },

  [theme.breakpoints.up("lg")]: {
    marginBottom: "100px",
  },
}));

const HowHelpsWinContentWrapperBox = styled(Box)(({ theme }) => ({
  width: "100%",

  [theme.breakpoints.up("md")]: {
    width: "90%",
    margin: "0 auto",
  },

  [theme.breakpoints.up("lg")]: {
    width: "72%",
  },
}));

const HowHelpsWinInfoBlockWrapperBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

export const HowHelpsWin = () => {
  return (
    <HowHelpsWinBox>
      <HowHelpsWinContentWrapperBox>
        <TitleComp text="how_helps_win_title" />
        <HowHelpsWinInfoBlockWrapperBox>
          <HowHelpsWinInfoCartsNumber arrData={howHelpsWinData1} />
          <HowHelpsWinInfoCartsNumber arrData={howHelpsWinData2} />
        </HowHelpsWinInfoBlockWrapperBox>
      </HowHelpsWinContentWrapperBox>
    </HowHelpsWinBox>
  );
};
