import React from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { TextTranslComp } from "./TextTranslComp";

export const ErrorMessage = ({ errorMessage }) => {
  const theme = useTheme();
  const isSmallScreenMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        display: "flex",
        marginBottom: { xs: "12px", md: "16px" },
      }}
    >
      <ErrorIcon
        sx={{
          marginRight: { xs: "8px", md: "12px" },
          marginTop: { xs: "3px", md: "5px" },
          width: { xs: "16px", md: "24px" },
          height: { xs: "16px", md: "24px" },
          color: theme.palette.red,
        }}
      />
      <TextTranslComp
        variant={isSmallScreenMd ? "Body6" : "Body3"}
        sx={{ color: theme.palette.red }}
        text={errorMessage}
      />
    </Box>
  );
};
