import React from "react";
import { Box, styled, useTheme, useMediaQuery } from "@mui/material";
import { TitleComp } from "../common/TitleComp";
import { makesEasyForYouData } from "../../dataUI/homeData";
import { InfoCart } from "../common/InfoCart";
import { TextComp } from "../common/TextComp";

const MakesEasyForYouBox = styled(Box)(({ theme }) => ({
  width: "100%",
  margin: "0 auto 16px",
  padding: "0 16px",

  [theme.breakpoints.up("md")]: {
    width: "94%",
    margin: "0 auto 80px",
    padding: 0,
  },

  [theme.breakpoints.up("lg")]: {
    margin: "0 auto 100px",
    width: "85%",
  },
}));

const MakesEasyForYouInfoCartsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

export const MakesEasyForYou = () => {
  const theme = useTheme();
  const isSmallScreenLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <MakesEasyForYouBox>
      <TitleComp
        text="makes_easy_for_you_title"
        sx={{ mb: { xs: "12px", lg: 2 } }}
      />
      <TextComp
        text="makes_easy_for_you_text"
        variant={isSmallScreenLg ? "Subtitle4" : "Body1"}
        sx={{ mb: { xs: 3, md: 5 }, textAlign: "center" }}
      />

      <MakesEasyForYouInfoCartsBox>
        {makesEasyForYouData?.map((item) => (
          <InfoCart
            item={item}
            key={item?.id}
            sx={{
              boxStyles: {
                width: { xs: "100%", md: "30%", lg: "28%" },
                mb: { xs: 3, md: 0 },
              },
              imgWrappStyles: {
                minHeight: "430px",
              },
            }}
          />
        ))}
      </MakesEasyForYouInfoCartsBox>
    </MakesEasyForYouBox>
  );
};
