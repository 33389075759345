export const informationBlockData = [
  {
    id: 1,
    imgSrc: "information_block_img_1",
    subTitle: "information_block_subtitle_1",
    textArr: ["information_block_text_1"],
  },
  {
    id: 2,
    imgSrc: "information_block_img_2",
    subTitle: "information_block_subtitle_2",
    textArr: ["information_block_text_2"],
  },
  {
    id: 3,
    imgSrc: "information_block_img_3",
    subTitle: "information_block_subtitle_3",
    textArr: ["information_block_text_3"],
  },
  {
    id: 4,
    imgSrc: "information_block_img_4",
    subTitle: "information_block_subtitle_4",
    textArr: ["information_block_text_4"],
  },
];

export const findBrandNameData = [
  {
    id: 1,
    imgSrc: "find_brand_name_img_1",
    subTitle: "find_brand_name_subtitle_1",
    textArr: ["find_brand_name_text_1"],
  },
  {
    id: 2,
    imgSrc: "find_brand_name_img_2",
    subTitle: "find_brand_name_subtitle_2",
    textArr: ["find_brand_name_text_2"],
  },
  {
    id: 3,
    imgSrc: "find_brand_name_img_3",
    subTitle: "find_brand_name_subtitle_3",
    textArr: ["find_brand_name_text_3"],
  },
];

export const languageMattersData = [
  {
    id: 1,
    imgSrc: "language_matters_img_1",
    subTitle: "language_matters_subtitle_1",
    textArrWeb: [
      "language_matters_text_1a",
      "language_matters_text_1b",
      "language_matters_text_1c",
      "language_matters_text_1d",
    ],
    textArrMobile: ["language_matters_text_1c", "language_matters_text_1d"],
  },
  {
    id: 2,
    imgSrc: "language_matters_img_2",
    subTitle: "language_matters_subtitle_2",
    textArrWeb: [
      "language_matters_text_2a",
      "language_matters_text_2b",
      "language_matters_text_2c",
    ],
    textArrMobile: ["language_matters_text_2a", "language_matters_text_2c"],
  },
  {
    id: 3,
    imgSrc: "language_matters_img_3",
    subTitle: "language_matters_subtitle_3",
    textArrWeb: [
      "language_matters_text_3a",
      "language_matters_text_3b",
      "language_matters_text_3c",
      "language_matters_text_3d",
    ],
    textArrMobile: ["language_matters_text_3a", "language_matters_text_3c"],
  },
];

export const howHelpsWinData1 = [
  {
    id: 1,
    subTitle: "how_helps_win_subtitle_1",
    textArr: ["how_helps_win_text_1"],
  },
  {
    id: 2,
    subTitle: "how_helps_win_subtitle_2",
    textArr: ["how_helps_win_text_2"],
  },
];

export const howHelpsWinData2 = [
  {
    id: 3,
    subTitle: "how_helps_win_subtitle_3",
    textArr: ["how_helps_win_text_3"],
  },
  {
    id: 4,
    subTitle: "how_helps_win_subtitle_4",
    textArr: ["how_helps_win_text_4"],
  },
];

export const makesEasyForYouData = [
  {
    id: 1,
    imgSrc: "makes_easy_for_you_img_1",
    subTitle: "makes_easy_for_you_subtitle_1",
    textArr: ["makes_easy_for_you_text_1"],
  },
  {
    id: 2,
    imgSrc: "makes_easy_for_you_img_2",
    subTitle: "makes_easy_for_you_subtitle_2",
    textArr: ["makes_easy_for_you_text_2"],
  },
  {
    id: 3,
    imgSrc: "makes_easy_for_you_img_3",
    subTitle: "makes_easy_for_you_subtitle_3",
    textArr: ["makes_easy_for_you_text_3"],
  },
];

export const linksData = [
  {
    id: 1,
    type: "terms",
  },
  {
    id: 2,
    type: "policy",
  },
  {
    id: 3,
    type: "cookie",
  },
];
