import React from "react";
import { Box, styled, useTheme } from "@mui/material";
import { useSelector } from "react-redux";

const RangeBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "6px",
  background: theme.palette.lightGrey,
  borderRadius: "4px",
  marginBottom: "36px",
}));

export const ProgressBarComp = () => {
  const activeStep = useSelector((state) => state.activeStep.value);
  const widthStep = (100 / 5) * activeStep;
  const theme = useTheme();

  return (
    <RangeBox>
      <Box
        width={`${widthStep}%`}
        height="6px"
        sx={{ background: theme.palette.blue, borderRadius: "4px" }}
      />
    </RangeBox>
  );
};
