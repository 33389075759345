export const mainContent = {
  //Main content translation
  "gender_title_aggressive": {
    en: `Start for FREE today!`,
    no: `Start gratis i dag!`,
  },
  "gender_title_casual": {
    en: `Start for FREE today!`,
    no: `Start gratis i dag!`,
  },
  "gender_title_mainstream": {
    en: `Start for FREE today!`,
    no: `Start gratis i dag!`,
  },
  "gender_form_text_aggressive": {
    en: `Attract women who understand you`,
    no: `Tiltrekke kvinner som forstår deg`,
  },
  "gender_form_text_casual": {
    en: `Attract women who understand you`,
    no: `Tiltrekke kvinner som forstår deg`,
  },
  "gender_form_text_mainstream": {
    en: `Attract women who understand you`,
    no: `Tiltrekke kvinner som forstår deg`,
  },
  "gender_subtitle_aggressive": {
    en: `I am`,
    no: `Jeg er `,
  },
  "gender_subtitle_casual": {
    en: `I am`,
    no: `Jeg er `,
  },
  "gender_subtitle_mainstream": {
    en: `I am`,
    no: `Jeg er `,
  },
  "looking_for_subtitle_aggressive": {
    en: `I am interested in`,
    no: `Jeg er interessert av`,
  },
  "looking_for_subtitle_casual": {
    en: `I am interested in`,
    no: `Jeg er interessert av`,
  },
  "looking_for_subtitle_mainstream": {
    en: `I am interested in`,
    no: `Jeg er interessert av`,
  },
  "male": {
    en: `Male`,
    no: `Mann`,
  },
  "female": {
    en: `Female`,
    no: `Dame`,
  },
  //Info blocks
  "information_block_subtitle_1_aggressive": {
    en: `Intelligent matchmaking`,
    no: `Intelligent matchmaking`,
  },
  "information_block_subtitle_1_casual": {
    en: `Intelligent matchmaking`,
    no: `Intelligent matchmaking`,
  },
  "information_block_subtitle_1_mainstream": {
    en: `Intelligent matchmaking`,
    no: `Intelligent matchmaking`,
  },
  "information_block_text_1_aggressive": {
    en: `Experience personalized matches that align with your unique preferences, streamlining your search for the perfect connection.`,
    no: `Opplev personlige kamper som stemmer overens med dine unike preferanser, og effektiviserer søket etter den perfekte forbindelsen.`,
  },
  "information_block_text_1_casual": {
    en: `Experience personalized matches that align with your unique preferences, streamlining your search for the perfect connection.`,
    no: `Opplev personlige kamper som stemmer overens med dine unike preferanser, og effektiviserer søket etter den perfekte forbindelsen.`,
  },
  "information_block_text_1_mainstream": {
    en: `Experience personalized matches that align with your unique preferences, streamlining your search for the perfect connection.`,
    no: `Opplev personlige kamper som stemmer overens med dine unike preferanser, og effektiviserer søket etter den perfekte forbindelsen.`,
  },
  "information_block_subtitle_2_aggressive": {
    en: `Members revealing their true self through detailed profiles`,
    no: `Medlemmer som avslører sitt sanne jeg gjennom detaljerte profiler`,
  },
  "information_block_subtitle_2_casual": {
    en: `Members revealing their true self through detailed profiles`,
    no: `Medlemmer som avslører sitt sanne jeg gjennom detaljerte profiler`,
  },
  "information_block_subtitle_2_mainstream": {
    en: `Members revealing their true self through detailed profiles`,
    no: `Medlemmer som avslører sitt sanne jeg gjennom detaljerte profiler`,
  },
  "information_block_text_2_aggressive": {
    en: `Genuine profiles offer a window into the real you, paving the way for deeper connections and authenticity.`,
    no: `Ekte profiler tilbyr et vindu inn i den virkelige du, og baner vei for dypere forbindelser og autentisitet.`,
  },
  "information_block_text_2_casual": {
    en: `Genuine profiles offer a window into the real you, paving the way for deeper connections and authenticity.`,
    no: `Ekte profiler tilbyr et vindu inn i den virkelige du, og baner vei for dypere forbindelser og autentisitet.`,
  },
  "information_block_text_2_mainstream": {
    en: `Genuine profiles offer a window into the real you, paving the way for deeper connections and authenticity.`,
    no: `Ekte profiler tilbyr et vindu inn i den virkelige du, og baner vei for dypere forbindelser og autentisitet.`,
  },
  "information_block_subtitle_3_aggressive": {
    en: `Let loose and fulfill your hidden desires`,
    no: `La løs og oppfylle dine skjulte ønsker`,
  },
  "information_block_subtitle_3_casual": {
    en: `Let loose and fulfill your hidden desires`,
    no: `La løs og oppfylle dine skjulte ønsker`,
  },
  "information_block_subtitle_3_mainstream": {
    en: `Let loose and fulfill your hidden desires`,
    no: `La løs og oppfylle dine skjulte ønsker`,
  },
  "information_block_text_3_aggressive": {
    en: `Embrace a world of flirtation and discovery, all while feeling free to express your deepest desires.`,
    no: `Omfavne en verden av flørt og oppdagelse, alt mens du føler deg fri til å uttrykke dine dypeste ønsker.`,
  },
  "information_block_text_3_casual": {
    en: `Embrace a world of flirtation and discovery, all while feeling free to express your deepest desires.`,
    no: `Omfavne en verden av flørt og oppdagelse, alt mens du føler deg fri til å uttrykke dine dypeste ønsker.`,
  },
  "information_block_text_3_mainstream": {
    en: `Embrace a world of flirtation and discovery, all while feeling free to express your deepest desires.`,
    no: `Omfavne en verden av flørt og oppdagelse, alt mens du føler deg fri til å uttrykke dine dypeste ønsker.`,
  },
  "information_block_subtitle_4_aggressive": {
    en: `It is safe, secure and discreet`,
    no: `Det er trygt, sikkert og diskret`,
  },
  "information_block_subtitle_4_casual": {
    en: `It is safe, secure and discreet`,
    no: `Det er trygt, sikkert og diskret`,
  },
  "information_block_subtitle_4_mainstream": {
    en: `It is safe, secure and discreet`,
    no: `Det er trygt, sikkert og diskret`,
  },
  "information_block_text_4_aggressive": {
    en: `Privacy guaranteed. \nConnect confidently with robust protection for your data and discretion at the heart of the experience.`,
    no: `Personvern garantert. \nNconnect trygt med robust beskyttelse for dine data og skjønn i hjertet av opplevelsen.`,
  },
  "information_block_text_4_casual": {
    en: `Privacy guaranteed. \nConnect confidently with robust protection for your data and discretion at the heart of the experience.`,
    no: `Personvern garantert. \nNconnect trygt med robust beskyttelse for dine data og skjønn i hjertet av opplevelsen.`,
  },
  "information_block_text_4_mainstream": {
    en: `Privacy guaranteed. \nConnect confidently with robust protection for your data and discretion at the heart of the experience.`,
    no: `Personvern garantert. \nNconnect trygt med robust beskyttelse for dine data og skjønn i hjertet av opplevelsen.`,
  },
  "find_brand_name_title_aggressive": {
    en: `Find your perfect match on IntimFlørts`,
    no: `Finn din perfekte match på "Merkenavn"`,
  },
  "find_brand_name_title_casual": {
    en: `Find your perfect match on IntimFlørts`,
    no: `Finn din perfekte match på "Merkenavn"`,
  },
  "find_brand_name_title_mainstream": {
    en: `Find your perfect match on IntimFlørts`,
    no: `Finn din perfekte match på "Merkenavn"`,
  },
  "find_brand_name_subtitle_1_aggressive": {
    en: `Your safety is guaranteed.`,
    no: `Din sikkerhet er garantert.`,
  },
  "find_brand_name_subtitle_1_casual": {
    en: `Your safety is guaranteed.`,
    no: `Din sikkerhet er garantert.`,
  },
  "find_brand_name_subtitle_1_mainstream": {
    en: `Your safety is guaranteed.`,
    no: `Din sikkerhet er garantert.`,
  },
  "find_brand_name_text_1_aggressive": {
    en: `Your trust is our foundation. With our sophisticated reviewing and flagging system, we ensure that your journey in finding a match is comfortable and secure. Feel at ease as you navigate through Brand, where your safety is our priority.`,
    no: `Din tillit er vårt grunnlag. Med vårt sofistikerte gjennomgangs- og flaggsystem sikrer vi at reisen din med å finne en kamp er komfortabel og sikker. Føl deg vel når du navigerer gjennom merkevaren, der sikkerheten din er vår prioritet.`,
  },
  "find_brand_name_text_1_casual": {
    en: `Your trust is our foundation. With our sophisticated reviewing and flagging system, we ensure that your journey in finding a match is comfortable and secure. Feel at ease as you navigate through Brand, where your safety is our priority.`,
    no: `Din tillit er vårt grunnlag. Med vårt sofistikerte gjennomgangs- og flaggsystem sikrer vi at reisen din med å finne en kamp er komfortabel og sikker. Føl deg vel når du navigerer gjennom merkevaren, der sikkerheten din er vår prioritet.`,
  },
  "find_brand_name_text_1_mainstream": {
    en: `Your trust is our foundation. With our sophisticated reviewing and flagging system, we ensure that your journey in finding a match is comfortable and secure. Feel at ease as you navigate through Brand, where your safety is our priority.`,
    no: `Din tillit er vårt grunnlag. Med vårt sofistikerte gjennomgangs- og flaggsystem sikrer vi at reisen din med å finne en kamp er komfortabel og sikker. Føl deg vel når du navigerer gjennom merkevaren, der sikkerheten din er vår prioritet.`,
  },
  "find_brand_name_subtitle_2_aggressive": {
    en: `High chances of finding the kind of connection that you're looking for`,
    no: `Høye sjanser for å finne den typen forbindelse du leter etter`,
  },
  "find_brand_name_text_2_aggressive": {
    en: `Uncover a world of possibility and find someone who clicks with you. Our platform offers high match potential, steering you towards those who complement your personality and preferences.`,
    no: `Avdekke en verden av muligheter og finn noen som klikker med deg. Vår plattform tilbyr et høyt matchpotensial, og styrer deg mot de som kompletterer din personlighet og preferanser.`,
  },
  "find_brand_name_subtitle_3_aggressive": {
    en: `Chat, share photos, and more!`,
    no: `Chat, del bilder og mer!`,
  },
  "find_brand_name_text_3_aggressive": {
    en: `Engage in lively chats, exchange photos, and discover each other's interests. IntimFlørts offers a suite of communication tools designed to enhance your connection experience, all within the platform.`,
    no: `Delta i livlige chatter, utveksle bilder og oppdag hverandres interesser. IntimFlørts tilbyr en serie kommunikasjonsverktøy designet for å forbedre tilkoblingsopplevelsen din, alt innenfor plattformen.`,
  },
  "find_brand_name_subtitle_2_casual": {
    en: `High chances of finding the kind of connection that you're looking for`,
    no: `Høye sjanser for å finne den typen forbindelse du leter etter`,
  },
  "find_brand_name_text_2_casual": {
    en: `Uncover a world of possibility and find someone who clicks with you. Our platform offers high match potential, steering you towards those who complement your personality and preferences.`,
    no: `Avdekke en verden av muligheter og finn noen som klikker med deg. Vår plattform tilbyr et høyt matchpotensial, og styrer deg mot de som kompletterer din personlighet og preferanser.`,
  },
  "find_brand_name_subtitle_3_casual": {
    en: `Chat, share photos, and more!`,
    no: `Chat, del bilder og mer!`,
  },
  "find_brand_name_text_3_casual": {
    en: `Engage in lively chats, exchange photos, and discover each other's interests. IntimFlørts offers a suite of communication tools designed to enhance your connection experience, all within the platform.`,
    no: `Delta i livlige chatter, utveksle bilder og oppdag hverandres interesser. IntimFlørts tilbyr en serie kommunikasjonsverktøy designet for å forbedre tilkoblingsopplevelsen din, alt innenfor plattformen.`,
  },
  "find_brand_name_subtitle_2_mainstream": {
    en: `High chances of finding the kind of connection that you're looking for`,
    no: `Høye sjanser for å finne den typen forbindelse du leter etter`,
  },
  "find_brand_name_text_2_mainstream": {
    en: `Uncover a world of possibility and find someone who clicks with you. Our platform offers high match potential, steering you towards those who complement your personality and preferences.`,
    no: `Avdekke en verden av muligheter og finn noen som klikker med deg. Vår plattform tilbyr et høyt matchpotensial, og styrer deg mot de som kompletterer din personlighet og preferanser.`,
  },
  "find_brand_name_subtitle_3_mainstream": {
    en: `Chat, share photos, and more!`,
    no: `Chat, del bilder og mer!`,
  },
  "find_brand_name_text_3_mainstream": {
    en: `Engage in lively chats, exchange photos, and discover each other's interests. IntimFlørts offers a suite of communication tools designed to enhance your connection experience, all within the platform.`,
    no: `Delta i livlige chatter, utveksle bilder og oppdag hverandres interesser. IntimFlørts tilbyr en serie kommunikasjonsverktøy designet for å forbedre tilkoblingsopplevelsen din, alt innenfor plattformen.`,
  },
  "why_brand_name_title_aggressive": {
    en: `Why IntimFlørts`,
    no: `Hvorfor IntimFlørts`,
  },
  "why_brand_name_title_casual": {
    en: `Why IntimFlørts`,
    no: `Hvorfor IntimFlørts`,
  },
  "why_brand_name_title_mainstream": {
    en: `Why IntimFlørts`,
    no: `Hvorfor IntimFlørts`,
  },
  "why_brand_name_text_1_aggressive": {
    en: `In the sea of social networks, IntimFlørts stands out as your compass in finding genuine connections. Why choose us? Because we don't just promise matches, we dive into the essence of compatibility. Our intelligent matchmaking isn't a game of chance, it's a science tailored to you. We celebrate individuality, fostering a community where members feel empowered to share their true selves. With Brand, you're not just another profile in the crowd – you're on a path to a connection that resonates.`,
    no: `I havet av sosiale nettverk skiller IntimFlørts seg ut som ditt kompass når det gjelder å finne ekte forbindelser. Hvorfor velge oss? Fordi vi ikke bare lover kamper, dykker vi inn i essensen av kompatibilitet. Vår intelligente matchmaking er ikke et sjansespill, det er en vitenskap skreddersydd for deg. Vi feirer individualitet og fremmer et samfunn der medlemmene føler seg bemyndiget til å dele sine sanne selv. Med IntimFlørts er du ikke bare en annen profil i mengden – du er på vei til en forbindelse som resonerer.`,
  },
  "why_brand_name_text_1_casual": {
    en: `In the sea of social networks, IntimFlørts stands out as your compass in finding genuine connections. Why choose us? Because we don't just promise matches, we dive into the essence of compatibility. Our intelligent matchmaking isn't a game of chance, it's a science tailored to you. We celebrate individuality, fostering a community where members feel empowered to share their true selves. With Brand, you're not just another profile in the crowd – you're on a path to a connection that resonates.`,
    no: `I havet av sosiale nettverk skiller IntimFlørts seg ut som ditt kompass når det gjelder å finne ekte forbindelser. Hvorfor velge oss? Fordi vi ikke bare lover kamper, dykker vi inn i essensen av kompatibilitet. Vår intelligente matchmaking er ikke et sjansespill, det er en vitenskap skreddersydd for deg. Vi feirer individualitet og fremmer et samfunn der medlemmene føler seg bemyndiget til å dele sine sanne selv. Med IntimFlørts er du ikke bare en annen profil i mengden – du er på vei til en forbindelse som resonerer.`,
  },
  "why_brand_name_text_1_mainstream": {
    en: `In the sea of social networks, IntimFlørts stands out as your compass in finding genuine connections. Why choose us? Because we don't just promise matches, we dive into the essence of compatibility. Our intelligent matchmaking isn't a game of chance, it's a science tailored to you. We celebrate individuality, fostering a community where members feel empowered to share their true selves. With Brand, you're not just another profile in the crowd – you're on a path to a connection that resonates.`,
    no: `I havet av sosiale nettverk skiller IntimFlørts seg ut som ditt kompass når det gjelder å finne ekte forbindelser. Hvorfor velge oss? Fordi vi ikke bare lover kamper, dykker vi inn i essensen av kompatibilitet. Vår intelligente matchmaking er ikke et sjansespill, det er en vitenskap skreddersydd for deg. Vi feirer individualitet og fremmer et samfunn der medlemmene føler seg bemyndiget til å dele sine sanne selv. Med IntimFlørts er du ikke bare en annen profil i mengden – du er på vei til en forbindelse som resonerer.`,
  },
  "why_brand_name_text_2_aggressive": {
    en: `And beyond the spark of chemistry, we uphold the pillars of safety and privacy. Our commitment to these principles creates a secure environment where you can search, flirt, and discover without hesitation. IntimFlørts is more than a platform, it's where your journey for meaningful encounters begins.`,
    no: `Og utover gnisten av kjemi, opprettholder vi søylene for sikkerhet og privatliv. Vårt engasjement for disse prinsippene skaper et sikkert miljø der du kan søke, flørte og oppdage uten å nøle. IntimFlørts er mer enn en plattform, det er der reisen din for meningsfulle møter begynner.`,
  },
  "why_brand_name_text_2_casual": {
    en: `And beyond the spark of chemistry, we uphold the pillars of safety and privacy. Our commitment to these principles creates a secure environment where you can search, flirt, and discover without hesitation. IntimFlørts is more than a platform, it's where your journey for meaningful encounters begins.`,
    no: `Og utover gnisten av kjemi, opprettholder vi søylene for sikkerhet og privatliv. Vårt engasjement for disse prinsippene skaper et sikkert miljø der du kan søke, flørte og oppdage uten å nøle. IntimFlørts er mer enn en plattform, det er der reisen din for meningsfulle møter begynner.`,
  },
  "why_brand_name_text_2_mainstream": {
    en: `And beyond the spark of chemistry, we uphold the pillars of safety and privacy. Our commitment to these principles creates a secure environment where you can search, flirt, and discover without hesitation. IntimFlørts is more than a platform, it's where your journey for meaningful encounters begins.`,
    no: `Og utover gnisten av kjemi, opprettholder vi søylene for sikkerhet og privatliv. Vårt engasjement for disse prinsippene skaper et sikkert miljø der du kan søke, flørte og oppdage uten å nøle. IntimFlørts er mer enn en plattform, det er der reisen din for meningsfulle møter begynner.`,
  },
  "language_matters_title_aggressive": {
    en: `Language matters when you're flirting`,
    no: `Språk betyr noe når du flørter`,
  },
  "language_matters_title_casual": {
    en: `Language matters when you're flirting`,
    no: `Språk betyr noe når du flørter`,
  },
  "language_matters_title_mainstream": {
    en: `Language matters when you're flirting`,
    no: `Språk betyr noe når du flørter`,
  },
  "language_matters_subtitle_1_aggressive": {
    en: `Profile Descriptions`,
    no: `Profilbeskrivelser`,
  },
  "language_matters_subtitle_1_casual": {
    en: `Profile Descriptions`,
    no: `Profilbeskrivelser`,
  },
  "language_matters_subtitle_1_mainstream": {
    en: `Profile Descriptions`,
    no: `Profilbeskrivelser`,
  },
  "language_matters_text_1a_aggressive": {
    en: `Examine the language you use to portray yourself. While images are often lauded for their communicative value, our members frequently emphasize the superior significance of a well-crafted profile description over a photograph.`,
    no: `Undersøk språket du bruker for å fremstille deg selv. Mens bilder ofte blir hyllet for sin kommunikative verdi, understreker medlemmene våre ofte den overlegne betydningen av en godt laget profilbeskrivelse over et fotografi.`,
  },
  "language_matters_text_1a_casual": {
    en: `Examine the language you use to portray yourself. While images are often lauded for their communicative value, our members frequently emphasize the superior significance of a well-crafted profile description over a photograph.`,
    no: `Undersøk språket du bruker for å fremstille deg selv. Mens bilder ofte blir hyllet for sin kommunikative verdi, understreker medlemmene våre ofte den overlegne betydningen av en godt laget profilbeskrivelse over et fotografi.`,
  },
  "language_matters_text_1a_mainstream": {
    en: `Examine the language you use to portray yourself. While images are often lauded for their communicative value, our members frequently emphasize the superior significance of a well-crafted profile description over a photograph.`,
    no: `Undersøk språket du bruker for å fremstille deg selv. Mens bilder ofte blir hyllet for sin kommunikative verdi, understreker medlemmene våre ofte den overlegne betydningen av en godt laget profilbeskrivelse over et fotografi.`,
  },
  "language_matters_text_1b_aggressive": {
    en: `It's crucial to invest effort into crafting your textual representation to ensure it is the finest it can be.`,
    no: `Det er avgjørende å investere innsats for å lage din tekstrepresentasjon for å sikre at den er den fineste den kan være.`,
  },
  "language_matters_text_1b_casual": {
    en: `It's crucial to invest effort into crafting your textual representation to ensure it is the finest it can be.`,
    no: `Det er avgjørende å investere innsats for å lage din tekstrepresentasjon for å sikre at den er den fineste den kan være.`,
  },
  "language_matters_text_1b_mainstream": {
    en: `It's crucial to invest effort into crafting your textual representation to ensure it is the finest it can be.`,
    no: `Det er avgjørende å investere innsats for å lage din tekstrepresentasjon for å sikre at den er den fineste den kan være.`,
  },
  "language_matters_text_1c_aggressive": {
    en: `Introducing yourself can be daunting, it's always challenging to find the perfect approach and craft a message that captures attention.`,
    no: `Å introdusere deg selv kan være skremmende, det er alltid utfordrende å finne den perfekte tilnærmingen og lage et budskap som fanger oppmerksomhet.`,
  },
  "language_matters_text_1c_casual": {
    en: `Introducing yourself can be daunting, it's always challenging to find the perfect approach and craft a message that captures attention.`,
    no: `Å introdusere deg selv kan være skremmende, det er alltid utfordrende å finne den perfekte tilnærmingen og lage et budskap som fanger oppmerksomhet.`,
  },
  "language_matters_text_1c_mainstream": {
    en: `Introducing yourself can be daunting, it's always challenging to find the perfect approach and craft a message that captures attention.`,
    no: `Å introdusere deg selv kan være skremmende, det er alltid utfordrende å finne den perfekte tilnærmingen og lage et budskap som fanger oppmerksomhet.`,
  },
  "language_matters_text_1d_aggressive": {
    en: `Merely writing 'hello' might not be enough to distinguish you during social interactions. In the realm of flirting, initial impressions are pivotal, and the first words you share online can have a profound impact.`,
    no: `Bare det å skrive 'hei' er kanskje ikke nok til å skille deg under sosiale interaksjoner. I området for flørting er innledende inntrykk sentrale, og de første ordene du deler på nettet kan ha en dyp innvirkning.`,
  },
  "language_matters_text_1d_casual": {
    en: `Merely writing 'hello' might not be enough to distinguish you during social interactions. In the realm of flirting, initial impressions are pivotal, and the first words you share online can have a profound impact.`,
    no: `Bare det å skrive 'hei' er kanskje ikke nok til å skille deg under sosiale interaksjoner. I området for flørting er innledende inntrykk sentrale, og de første ordene du deler på nettet kan ha en dyp innvirkning.`,
  },
  "language_matters_text_1d_mainstream": {
    en: `Merely writing 'hello' might not be enough to distinguish you during social interactions. In the realm of flirting, initial impressions are pivotal, and the first words you share online can have a profound impact.`,
    no: `Bare det å skrive 'hei' er kanskje ikke nok til å skille deg under sosiale interaksjoner. I området for flørting er innledende inntrykk sentrale, og de første ordene du deler på nettet kan ha en dyp innvirkning.`,
  },
  "language_matters_subtitle_2_aggressive": {
    en: `Breaking the Ice`,
    no: `Bryte isen`,
  },
  "language_matters_subtitle_2_casual": {
    en: `Breaking the Ice`,
    no: `Bryte isen`,
  },
  "language_matters_subtitle_2_mainstream": {
    en: `Breaking the Ice`,
    no: `Bryte isen`,
  },
  "language_matters_text_2a_aggressive": {
    en: `Starting a conversation smoothly involves a little homework and genuine interest. Before sending that first message, review the person's profile for shared interests or familiar locations.`,
    no: `Å starte en samtale innebærer litt lekser og ekte interesse. Før du sender den første meldingen, kan du gjennomgå personens profil for delte interesser eller kjente steder.`,
  },
  "language_matters_text_2a_casual": {
    en: `Starting a conversation smoothly involves a little homework and genuine interest. Before sending that first message, review the person's profile for shared interests or familiar locations.`,
    no: `Å starte en samtale innebærer litt lekser og ekte interesse. Før du sender den første meldingen, kan du gjennomgå personens profil for delte interesser eller kjente steder.`,
  },
  "language_matters_text_2a_mainstream": {
    en: `Starting a conversation smoothly involves a little homework and genuine interest. Before sending that first message, review the person's profile for shared interests or familiar locations.`,
    no: `Å starte en samtale innebærer litt lekser og ekte interesse. Før du sender den første meldingen, kan du gjennomgå personens profil for delte interesser eller kjente steder.`,
  },
  "language_matters_text_2b_aggressive": {
    en: `Using this as a starting point, you might comment on a mutual hobby or a beloved place both of you know.This personalized approach demonstrates that you've paid attention and are interested in a meaningful connection.`,
    no: `Ved å bruke dette som utgangspunkt, kan du kommentere en gjensidig hobby eller et elsket sted begge dere vet. Denne personlige tilnærmingen viser at du har lagt merke til og er interessert i en meningsfull forbindelse.`,
  },
  "language_matters_text_2b_casual": {
    en: `Using this as a starting point, you might comment on a mutual hobby or a beloved place both of you know.This personalized approach demonstrates that you've paid attention and are interested in a meaningful connection.`,
    no: `Ved å bruke dette som utgangspunkt, kan du kommentere en gjensidig hobby eller et elsket sted begge dere vet. Denne personlige tilnærmingen viser at du har lagt merke til og er interessert i en meningsfull forbindelse.`,
  },
  "language_matters_text_2b_mainstream": {
    en: `Using this as a starting point, you might comment on a mutual hobby or a beloved place both of you know.This personalized approach demonstrates that you've paid attention and are interested in a meaningful connection.`,
    no: `Ved å bruke dette som utgangspunkt, kan du kommentere en gjensidig hobby eller et elsket sted begge dere vet. Denne personlige tilnærmingen viser at du har lagt merke til og er interessert i en meningsfull forbindelse.`,
  },
  "language_matters_text_2c_aggressive": {
    en: `Keep it light, perhaps with a dash of humor, and pose open-ended questions to encourage a flowing dialogue. Such thoughtful and tailored icebreakers can set the stage for a lively and enjoyable conversation.`,
    no: `Hold det lett, kanskje med en smule humor, og still åpne spørsmål for å oppmuntre til en flytende dialog. Slike gjennomtenkte og skreddersydde isbrytere kan sette scenen for en livlig og hyggelig samtale.`,
  },
  "language_matters_text_2c_casual": {
    en: `Keep it light, perhaps with a dash of humor, and pose open-ended questions to encourage a flowing dialogue. Such thoughtful and tailored icebreakers can set the stage for a lively and enjoyable conversation.`,
    no: `Hold det lett, kanskje med en smule humor, og still åpne spørsmål for å oppmuntre til en flytende dialog. Slike gjennomtenkte og skreddersydde isbrytere kan sette scenen for en livlig og hyggelig samtale.`,
  },
  "language_matters_text_2c_mainstream": {
    en: `Keep it light, perhaps with a dash of humor, and pose open-ended questions to encourage a flowing dialogue. Such thoughtful and tailored icebreakers can set the stage for a lively and enjoyable conversation.`,
    no: `Hold det lett, kanskje med en smule humor, og still åpne spørsmål for å oppmuntre til en flytende dialog. Slike gjennomtenkte og skreddersydde isbrytere kan sette scenen for en livlig og hyggelig samtale.`,
  },
  "language_matters_subtitle_3_aggressive": {
    en: `Art of Flirting`,
    no: `Kunst av flørting`,
  },
  "language_matters_subtitle_3_casual": {
    en: `Art of Flirting`,
    no: `Kunst av flørting`,
  },
  "language_matters_subtitle_3_mainstream": {
    en: `Art of Flirting`,
    no: `Kunst av flørting`,
  },
  "language_matters_text_3a_aggressive": {
    en: `Flirting is the artful side of communication in the game of attraction, providing a playful yet meaningful way to show interest.`,
    no: `Flørting er den kunstneriske siden av kommunikasjonen i tiltrekningen, og gir en leken, men meningsfull måte å vise interesse på.`,
  },
  "language_matters_text_3a_casual": {
    en: `Flirting is the artful side of communication in the game of attraction, providing a playful yet meaningful way to show interest.`,
    no: `Flørting er den kunstneriske siden av kommunikasjonen i tiltrekningen, og gir en leken, men meningsfull måte å vise interesse på.`,
  },
  "language_matters_text_3a_mainstream": {
    en: `Flirting is the artful side of communication in the game of attraction, providing a playful yet meaningful way to show interest.`,
    no: `Flørting er den kunstneriske siden av kommunikasjonen i tiltrekningen, og gir en leken, men meningsfull måte å vise interesse på.`,
  },
  "language_matters_text_3b_aggressive": {
    en: `It's about the right blend of light-hearted banter, subtle compliments, and engaging body language.`,
    no: `Det handler om den rette blandingen av letthjertede småting, subtile komplimenter og engasjerende kroppsspråk.`,
  },
  "language_matters_text_3b_casual": {
    en: `It's about the right blend of light-hearted banter, subtle compliments, and engaging body language.`,
    no: `Det handler om den rette blandingen av letthjertede småting, subtile komplimenter og engasjerende kroppsspråk.`,
  },
  "language_matters_text_3b_mainstream": {
    en: `It's about the right blend of light-hearted banter, subtle compliments, and engaging body language.`,
    no: `Det handler om den rette blandingen av letthjertede småting, subtile komplimenter og engasjerende kroppsspråk.`,
  },
  "language_matters_text_3c_aggressive": {
    en: `Done respectfully, it can charm and signal your intentions, creating a warm rapport. It's vital, though, to be sensitive to the other person's reactions, ensuring that the flirtation is enjoyable and consensual.`,
    no: `Gjort respekt, kan det sjarmere og signalisere intensjonene dine, og skape en varm rapport. Det er imidlertid viktig å være følsom for den andre personens reaksjoner, og sikre at flørtingen er hyggelig og konsensus.`,
  },
  "language_matters_text_3c_casual": {
    en: `Done respectfully, it can charm and signal your intentions, creating a warm rapport. It's vital, though, to be sensitive to the other person's reactions, ensuring that the flirtation is enjoyable and consensual.`,
    no: `Gjort respekt, kan det sjarmere og signalisere intensjonene dine, og skape en varm rapport. Det er imidlertid viktig å være følsom for den andre personens reaksjoner, og sikre at flørtingen er hyggelig og konsensus.`,
  },
  "language_matters_text_3c_mainstream": {
    en: `Done respectfully, it can charm and signal your intentions, creating a warm rapport. It's vital, though, to be sensitive to the other person's reactions, ensuring that the flirtation is enjoyable and consensual.`,
    no: `Gjort respekt, kan det sjarmere og signalisere intensjonene dine, og skape en varm rapport. Det er imidlertid viktig å være følsom for den andre personens reaksjoner, og sikre at flørtingen er hyggelig og konsensus.`,
  },
  "language_matters_text_3d_aggressive": {
    en: `When reciprocated, flirting can transform a simple conversation into an exciting possibility, potentially sparking a deeper connection.`,
    no: `Når du gjengis, kan flørting forvandle en enkel samtale til en spennende mulighet, og potensielt vekke en dypere forbindelse.`,
  },
  "language_matters_text_3d_casual": {
    en: `When reciprocated, flirting can transform a simple conversation into an exciting possibility, potentially sparking a deeper connection.`,
    no: `Når du gjengis, kan flørting forvandle en enkel samtale til en spennende mulighet, og potensielt vekke en dypere forbindelse.`,
  },
  "language_matters_text_3d_mainstream": {
    en: `When reciprocated, flirting can transform a simple conversation into an exciting possibility, potentially sparking a deeper connection.`,
    no: `Når du gjengis, kan flørting forvandle en enkel samtale til en spennende mulighet, og potensielt vekke en dypere forbindelse.`,
  },
  "how_helps_win_title_aggressive": {
    en: `How IntimFlørts Helps You Win`,
    no: `Hvordan IntimFlørts hjelper deg å vinne`,
  },
  "how_helps_win_title_casual": {
    en: `How IntimFlørts Helps You Win`,
    no: `Hvordan IntimFlørts hjelper deg å vinne`,
  },
  "how_helps_win_title_mainstream": {
    en: `How IntimFlørts Helps You Win`,
    no: `Hvordan IntimFlørts hjelper deg å vinne`,
  },
  "how_helps_win_subtitle_1_aggressive": {
    en: `Easy Sign Up and User-Friendly!`,
    no: `Enkel registrering og brukervennlig!`,
  },
  "how_helps_win_subtitle_1_casual": {
    en: `Easy Sign Up and User-Friendly!`,
    no: `Enkel registrering og brukervennlig!`,
  },
  "how_helps_win_subtitle_1_mainstream": {
    en: `Easy Sign Up and User-Friendly!`,
    no: `Enkel registrering og brukervennlig!`,
  },
  "how_helps_win_text_1_aggressive": {
    en: `Get started swiftly with IntimFlørts quick sign-up process and navigate love with our intuitive interface. It's dating made straightforward, focusing on connections, not complications.`,
    no: `Kom raskt i gang med IntimFlørts raske påmeldingsprosess og naviger kjærlighet med vårt intuitive grensesnitt. Det er dating som er gjort enkelt, med fokus på forbindelser, ikke komplikasjoner.`,
  },
  "how_helps_win_text_1_casual": {
    en: `Get started swiftly with IntimFlørts quick sign-up process and navigate love with our intuitive interface. It's dating made straightforward, focusing on connections, not complications.`,
    no: `Kom raskt i gang med IntimFlørts raske påmeldingsprosess og naviger kjærlighet med vårt intuitive grensesnitt. Det er dating som er gjort enkelt, med fokus på forbindelser, ikke komplikasjoner.`,
  },
  "how_helps_win_text_1_mainstream": {
    en: `Get started swiftly with IntimFlørts quick sign-up process and navigate love with our intuitive interface. It's dating made straightforward, focusing on connections, not complications.`,
    no: `Kom raskt i gang med IntimFlørts raske påmeldingsprosess og naviger kjærlighet med vårt intuitive grensesnitt. Det er dating som er gjort enkelt, med fokus på forbindelser, ikke komplikasjoner.`,
  },
  "how_helps_win_subtitle_2_aggressive": {
    en: `Find Your Perfect Match!`,
    no: `Finn din perfekte match!`,
  },
  "how_helps_win_subtitle_2_casual": {
    en: `Find Your Perfect Match!`,
    no: `Finn din perfekte match!`,
  },
  "how_helps_win_subtitle_2_mainstream": {
    en: `Find Your Perfect Match!`,
    no: `Finn din perfekte match!`,
  },
  "how_helps_win_text_2_aggressive": {
    en: `Our intelligent matchmaking fine-tunes the search for your perfect match on IntimFlørts. Say goodbye to endless searching and hello to tailor-made potential connections.`,
    no: `Vår intelligente matchmaking finjuster søket etter din perfekte match på IntimFlørts. Ta farvel med uendelig søk og hei til skreddersydde potensielle forbindelser.`,
  },
  "how_helps_win_text_2_casual": {
    en: `Our intelligent matchmaking fine-tunes the search for your perfect match on IntimFlørts. Say goodbye to endless searching and hello to tailor-made potential connections.`,
    no: `Vår intelligente matchmaking finjuster søket etter din perfekte match på IntimFlørts. Ta farvel med uendelig søk og hei til skreddersydde potensielle forbindelser.`,
  },
  "how_helps_win_text_2_mainstream": {
    en: `Our intelligent matchmaking fine-tunes the search for your perfect match on IntimFlørts. Say goodbye to endless searching and hello to tailor-made potential connections.`,
    no: `Vår intelligente matchmaking finjuster søket etter din perfekte match på IntimFlørts. Ta farvel med uendelig søk og hei til skreddersydde potensielle forbindelser.`,
  },
  "how_helps_win_subtitle_3_aggressive": {
    en: `Your Safety & Privacy Comes First!`,
    no: `Din sikkerhet og personvern kommer først!`,
  },
  "how_helps_win_subtitle_3_casual": {
    en: `Your Safety & Privacy Comes First!`,
    no: `Din sikkerhet og personvern kommer først!`,
  },
  "how_helps_win_subtitle_3_mainstream": {
    en: `Your Safety & Privacy Comes First!`,
    no: `Din sikkerhet og personvern kommer først!`,
  },
  "how_helps_win_text_3_aggressive": {
    en: `Your privacy is sacred, your safety is assured – IntimFlørts is committed to keeping your matching journey secure. Swipe with confidence, knowing we prioritize your peace of mind.`,
    no: `Personvernet ditt er hellig, sikkerheten din er sikret – merkevaren er opptatt av å holde din matchende reise sikker. Sveip av selvtillit, vel vitende om at vi prioriterer din trygghet.`,
  },
  "how_helps_win_text_3_casual": {
    en: `Your privacy is sacred, your safety is assured – IntimFlørts is committed to keeping your matching journey secure. Swipe with confidence, knowing we prioritize your peace of mind.`,
    no: `Personvernet ditt er hellig, sikkerheten din er sikret – merkevaren er opptatt av å holde din matchende reise sikker. Sveip av selvtillit, vel vitende om at vi prioriterer din trygghet.`,
  },
  "how_helps_win_text_3_mainstream": {
    en: `Your privacy is sacred, your safety is assured – IntimFlørts is committed to keeping your matching journey secure. Swipe with confidence, knowing we prioritize your peace of mind.`,
    no: `Personvernet ditt er hellig, sikkerheten din er sikret – merkevaren er opptatt av å holde din matchende reise sikker. Sveip av selvtillit, vel vitende om at vi prioriterer din trygghet.`,
  },
  "how_helps_win_subtitle_4_aggressive": {
    en: `No Annoying Monthly Subscriptions`,
    no: `Ingen irriterende månedlige abonnement`,
  },
  "how_helps_win_subtitle_4_casual": {
    en: `No Annoying Monthly Subscriptions`,
    no: `Ingen irriterende månedlige abonnement`,
  },
  "how_helps_win_subtitle_4_mainstream": {
    en: `No Annoying Monthly Subscriptions`,
    no: `Ingen irriterende månedlige abonnement`,
  },
  "how_helps_win_text_4_aggressive": {
    en: `Experience IntimFlørts without the burden of monthly fees. Delight in an uninterrupted journey of connection, paying only for what you use when you choose to.`,
    no: `Opplev IntimFlørts uten belastning av månedlige gebyrer. Gled deg over en uavbrutt forbindelse med forbindelse, og betaler bare for det du bruker når du velger å gjøre det.`,
  },
  "how_helps_win_text_4_casual": {
    en: `Experience IntimFlørts without the burden of monthly fees. Delight in an uninterrupted journey of connection, paying only for what you use when you choose to.`,
    no: `Opplev IntimFlørts uten belastning av månedlige gebyrer. Gled deg over en uavbrutt forbindelse med forbindelse, og betaler bare for det du bruker når du velger å gjøre det.`,
  },
  "how_helps_win_text_4_mainstream": {
    en: `Experience IntimFlørts without the burden of monthly fees. Delight in an uninterrupted journey of connection, paying only for what you use when you choose to.`,
    no: `Opplev IntimFlørts uten belastning av månedlige gebyrer. Gled deg over en uavbrutt forbindelse med forbindelse, og betaler bare for det du bruker når du velger å gjøre det.`,
  },
  "makes_easy_for_you_title_aggressive": {
    en: `IntimFlørts makes it easy for you :)`,
    no: `IntimFlørts gjør det enkelt for deg :)`,
  },
  "makes_easy_for_you_title_casual": {
    en: `IntimFlørts makes it easy for you :)`,
    no: `IntimFlørts gjør det enkelt for deg :)`,
  },
  "makes_easy_for_you_title_mainstream": {
    en: `IntimFlørts makes it easy for you :)`,
    no: `IntimFlørts gjør det enkelt for deg :)`,
  },
  "makes_easy_for_you_text_aggressive": {
    en: `IntimFlørts streamlines your journey to connection with simplicity at its core.`,
    no: `IntimFlørts effektiviserer reisen din til forbindelse med enkelhet i kjernen.`,
  },
  "makes_easy_for_you_text_casual": {
    en: `IntimFlørts streamlines your journey to connection with simplicity at its core.`,
    no: `IntimFlørts effektiviserer reisen din til forbindelse med enkelhet i kjernen.`,
  },
  "makes_easy_for_you_text_mainstream": {
    en: `IntimFlørts streamlines your journey to connection with simplicity at its core.`,
    no: `IntimFlørts effektiviserer reisen din til forbindelse med enkelhet i kjernen.`,
  },
  "makes_easy_for_you_subtitle_1_aggressive": {
    en: `Step 1: Your safety is guaranteed.`,
    no: `Trinn 1: Din sikkerhet er garantert.`,
  },
  "makes_easy_for_you_subtitle_1_casual": {
    en: `Step 1: Your safety is guaranteed.`,
    no: `Trinn 1: Din sikkerhet er garantert.`,
  },
  "makes_easy_for_you_subtitle_1_mainstream": {
    en: `Step 1: Your safety is guaranteed.`,
    no: `Trinn 1: Din sikkerhet er garantert.`,
  },
  "makes_easy_for_you_text_1_aggressive": {
    en: `Crafting your profile is quick and hassle-free, setting you up to showcase your best self in no time!`,
    no: `Å lage profilen din er rask og problemfri, og setter deg opp for å vise frem ditt beste selv på kort tid!`,
  },
  "makes_easy_for_you_text_1_casual": {
    en: `Crafting your profile is quick and hassle-free, setting you up to showcase your best self in no time!`,
    no: `Å lage profilen din er rask og problemfri, og setter deg opp for å vise frem ditt beste selv på kort tid!`,
  },
  "makes_easy_for_you_text_1_mainstream": {
    en: `Crafting your profile is quick and hassle-free, setting you up to showcase your best self in no time!`,
    no: `Å lage profilen din er rask og problemfri, og setter deg opp for å vise frem ditt beste selv på kort tid!`,
  },
  "makes_easy_for_you_subtitle_2_aggressive": {
    en: `Step 2: High chances of finding the kind of connection that you're looking for`,
    no: `Trinn 2: Høye sjanser for å finne den typen forbindelse du leter etter`,
  },
  "makes_easy_for_you_text_2_aggressive": {
    en: `With just a swipe, find your match and begin the exciting quest for companionship.`,
    no: `Med bare en sveip, finn kampen din og begynn den spennende søken etter kameratskap.`,
  },
  "makes_easy_for_you_subtitle_2_casual": {
    en: `Step 2: High chances of finding the kind of connection that you're looking for`,
    no: `Trinn 2: Høye sjanser for å finne den typen forbindelse du leter etter`,
  },
  "makes_easy_for_you_text_2_casual": {
    en: `With just a swipe, find your match and begin the exciting quest for companionship.`,
    no: `Med bare en sveip, finn kampen din og begynn den spennende søken etter kameratskap.`,
  },
  "makes_easy_for_you_subtitle_2_mainstream": {
    en: `Step 2: High chances of finding the kind of connection that you're looking for`,
    no: `Trinn 2: Høye sjanser for å finne den typen forbindelse du leter etter`,
  },
  "makes_easy_for_you_text_2_mainstream": {
    en: `With just a swipe, find your match and begin the exciting quest for companionship.`,
    no: `Med bare en sveip, finn kampen din og begynn den spennende søken etter kameratskap.`,
  },
  "makes_easy_for_you_subtitle_3_aggressive": {
    en: `Step 3: Chat, share photos, and more!`,
    no: `Trinn 3: Chat, del bilder og mer!`,
  },
  "makes_easy_for_you_text_3_aggressive": {
    en: `Dive into meaningful dialogue and let the spark of engaging conversations kindle the potential for something more.`,
    no: `Dykk i meningsfull dialog og la gnisten til å engasjere samtaler tenner potensialet for noe mer.`,
  },
  "makes_easy_for_you_subtitle_3_casual": {
    en: `Step 3: Chat, share photos, and more!`,
    no: `Trinn 3: Chat, del bilder og mer!`,
  },
  "makes_easy_for_you_text_3_casual": {
    en: `Dive into meaningful dialogue and let the spark of engaging conversations kindle the potential for something more.`,
    no: `Dykk i meningsfull dialog og la gnisten til å engasjere samtaler tenner potensialet for noe mer.`,
  },
  "makes_easy_for_you_subtitle_3_mainstream": {
    en: `Step 3: Chat, share photos, and more!`,
    no: `Trinn 3: Chat, del bilder og mer!`,
  },
  "makes_easy_for_you_text_3_mainstream": {
    en: `Dive into meaningful dialogue and let the spark of engaging conversations kindle the potential for something more.`,
    no: `Dykk i meningsfull dialog og la gnisten til å engasjere samtaler tenner potensialet for noe mer.`,
  },
  "footer_rights_reserved": {
    en: `All Rights Reserved.`,
    no: `Alle rettigheter forbeholdt.`,
  },
  "terms": {
    en: `Terms`,
     no: `Vilkår`,
  },
  "policy": {
    en: `Policy`,
    no: `Personvernerklæring`,
  },
  "cookie": {
    en: `Cookie Policy`,
    no: `Informasjonskapselerklæring`,
  },
  "signup_title_aggressive": {
    en: `Start creating your profile!`,
    no: `Begynn å skape profilen din!`,
  },
  "signup_title_casual": {
    en: `Start creating your profile!`,
    no: `Begynn å skape profilen din!`,
  },
  "signup_title_mainstream": {
    en: `Start creating your profile!`,
    no: `Begynn å skape profilen din!`,
  },
  "step1_question_aggressive": {
    en: `Where do you live?`,
    no: `Hvor bor du?`,
  },
  "step1_question_casual": {
    en: `Where do you live?`,
    no: `Hvor bor du?`,
  },
  "step1_question_mainstream": {
    en: `Where do you live?`,
    no: `Hvor bor du?`,
  },
  "step2_question_aggressive": {
    en: `When is your birthday?`,
    no: `Når har du bursdag?`,
  },
  "step2_question_casual": {
    en: `When is your birthday?`,
    no: `Når har du bursdag?`,
  },
  "step2_question_mainstream": {
    en: `When is your birthday?`,
    no: `Når har du bursdag?`,
  },
  "step3_question_aggressive": {
    en: `Choose a username`,
    no: `Velg et brukernavn`,
  },
  "step3_question_casual": {
    en: `Choose a username`,
    no: `Velg et brukernavn`,
  },
  "step3_question_mainstream": {
    en: `Choose a username`,
    no: `Velg et brukernavn`,
  },
  "step4_question_aggressive": {
    en: `Set your password`,
    no: `Angi passordet ditt`,
  },
  "step4_question_casual": {
    en: `Set your password`,
    no: `Angi passordet ditt`,
  },
  "step4_question_mainstream": {
    en: `Set your password`,
    no: `Angi passordet ditt`,
  },
  "step5_question_aggressive": {
    en: `What is your email?`,
    no: `Hva er epostadressen din?`,
  },
  "step5_question_casual": {
    en: `What is your email?`,
    no: `Hva er epostadressen din?`,
  },
  "step5_question_mainstream": {
    en: `What is your email?`,
    no: `Hva er epostadressen din?`,
  },
  "have account": {
    en: `Already have an account?`,
    no: `Allerede en konto?`,
  },
  "log In": {
    en: `Log In`,
    no: `Logg Inn`,
  },
  //Placeholder and static text
  "locationText": {
    en: `Search location by city, country or zip code`,
    no: `Søkeplassering etter by-, land- eller postnummer`,
  },
  "locationPlaceholder": {
    en: `Type your city, for example London`,
    no: `Skriv din by, for eksempel Oslo`,
  },
  "ageText": {
    en: `You must be at least 18 years old to use IntimFlorts.`,
    no: `Du må være minst 18 år for å bruke IntimFlørts.`,
  },
  "usernameText": {
    en: `Username must be 3-12 characters should exclude any personal details.`,
    no: `Brukernavnet må være 3-12 tegn og bør utelukke personlige detaljer.`,
  },
  "usernamePlaceholder": {
    en: `Username`,
    no: `Brukernavn`,
  },
  "passwordPlaceholder": {
    en: `Password`,
    no: `Passord`,
  },
  "emailPlaceholder": {
    en: `Add your email address`,
    no: `Legg til e -postadressen din`,
  },
  "passwordText": {
    en: `The password must be more than 6 characters long and include at least one number`,
    no: `Passordet må være lengre enn 6 tegn og inkludere minst ett tall`,
  },
  "emailText": {
    en: `Your email will not be disclosed publicly and will not be shared with external entities.`,
    no: `E -postadressen din blir ikke avslørt offentlig og vil ikke bli delt med eksterne enheter.`,
  },
  //Birthday selector options
  "day": {
    en: `Day`,
    no: `Dag`,
  },
  "month": {
    en: `Month`,
    no: `Måned`,
  },
  "year": {
    en: `Year`,
    no: `År`,
  },
  //ToS-labels
  "read and accept": {
    en: `I have read and accept the`,
    no: `Jeg har lest og aksepterer`,
  },
  "Terms of Service": {
    en: `Terms of Service`,
    no: `Tjenestevilkår`,
  },
  "and our": {
    en: `and our`,
    no: `og vår`,
  },
  "Privacy Statement": {
    en: `Privacy Statement`,
    no: `Personvernerklæring`,
  },
  //Months
  "January": {
    en: `January`,
    no: `Januar`,
  },
  "February": {
    en: `February`,
    no: `Februar`,
  },
  "March": {
    en: `March`,
    no: `Mars`,
  },
  "April": {
    en: `April`,
    no: `April`,
  },
  "May": {
    en: `May`,
    no: `Mai`,
  },
  "June": {
    en: `June`,
    no: `Juni`,
  },
  "July": {
    en: `July`,
    no: `Juli`,
  },
  "August": {
    en: `August`,
    no: `August`,
  },
  "September": {
    en: `September`,
    no: `September`,
  },
  "October": {
    en: `October`,
    no: `Oktober`,
  },
  "November": {
    en: `November`,
    no: `November`,
  },
  "December": {
    en: `December`,
    no: `Desember`,
  },
  //frontend error message translation content
  "locationErrMessFE": {
    en: `Location not found`,
    no: `Plassering ikke funnet`,
  },
  "usernameErrMessFE": {
    en: `The username must be 3-12 characters long and can only consist of letters, underscores, and standard special characters`,
    no: `Brukernavnet må være 3-12 tegn langt og kan bare bestå av bokstaver, understrekinger og standard spesialtegn.`,
  },
  "passwordErrMessFE": {
    en: `Your password doesn't meet our guidelines. Password must be 6-20 characters and contain at least one digit.`,
    no: `Passordet ditt oppfyller ikke våre retningslinjer. Passordet må være 6-20 tegn og inneholde minst ett tall.`,
  },
  "emailErrMessFE": {
    en: `The email address is not valid, please try again`,
    no: `E-postadressen er ikke gyldig, vennligst prøv igjen`,
  },
  //backend error message translation content
  "Field already exists.": {
    en: `An account containing identical details already exists in our system`,
    no: `En konto med identiske opplysninger finnes allerede i vårt system`,
  },
  "Google recaptcha error.": {
    en: `There has been an error with the Google reCAPTCHA verification process`,
    no: `Det har oppstått en feil med Google reCAPTCHA-verifiseringsprosessen`,
  },
  "Google recaptcha user suspicious behaviour.": {
    en: `Google reCAPTCHA has detected suspicious activity associated with your behavior`,
    no: `Google reCAPTCHA har oppdaget mistenkelig aktivitet knyttet til din oppførsel`,
  },
  "Provide google recaptcha token.": {
    en: `Please provide the token generated by the Google reCAPTCHA for verification`,
    no: `Vennligst oppgi tokenet generert av Google reCAPTCHA for verifikasjon`,
  },
  "Provide valid email.": {
    en: `Please enter a valid email address to proceed`,
    no: `Vennligst oppgi en gyldig e-postadresse for å fortsette`,
  },
  "User age must be above 18 years.": {
    en: `Users must be at least 18 years old to create an account`,
    no: `Brukere må være minst 18 år gamle for å opprette en konto`,
  },
  "Wrong user id provided.": {
    en: `The user ID you have provided is incorrect or does not exist`,
    no: `Bruker-ID-en du har oppgitt er feil eller eksisterer ikke`,
  },
  //Duplicate from backend error response
  "Username requirements don't match.": {
    en: `The username must be 3-12 characters long and can only consist of letters, underscores, and standard special characters`,
    no: `Brukernavnet må være 3-12 tegn langt og kan bare bestå av bokstaver, understrekinger og standard spesialtegn.`,
  },
  "Password requirements don't match.": {
    en: `Your password doesn't meet our guidelines. Password must be 6-20 characters and contain at least one digit.`,
    no: `Ditt passord overholder ikke våre krav. Det skal være på 6-20 tegn og må inkludere minst ett tall.`,
  },
};
